import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import { useScreenSize } from "../../common/functions";
import { ClassNames } from "../../components/classes";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { Icons } from "../../components/icons";

const SearchSpotlight: FC<{ searchText: string, searchItems: string[ ]}> = ({ searchText, searchItems }) => {
    const { isMobile } = useScreenSize();
    const [query, setQuery] = useState("");

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    }, []);

    const filteredImages = useMemo(() => {
        const queryLowered = query.toLowerCase();
        return searchItems.filter(image => image.toLowerCase().includes(queryLowered));
    }, [searchItems, query]);

    return <div className="relative">
            <input className={ClassNames.Input} value={query} placeholder={searchText} onChange={handleChange} />
            <AnimatePresence mode="sync">
                <motion.ul className="absolute top-12 flex gap-2 max-md:gap-1 flex-wrap md:w-[75%] max-md:w-[60%]" transition={{ staggerChildren: 0.01 }}>
                    {filteredImages.slice(0, isMobile ? 4 : 15).map((image, index) => <motion.li key={`docker-search-${image}`} className={classNames(ClassNames.Text, "border border-black/5 dark:border-white/5 bg-black/5 dark:bg-white/5 rounded-full px-4 py-1 backdrop-blur-sm max-md:text-sm")}
                        initial={{ x: 10, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        exit={{ x: 10, opacity: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.3 }}>
                        {image}
                    </motion.li>)}
                </motion.ul>
            </AnimatePresence>
        </div>
}

export const SearchSection: FC<{ title: string, description: string, searchText: string, searchItems: string[] }> = ({ title, description, searchText, searchItems }) => {
    return  <div className="flex items-center justify-center h-[100vh]">
    <div className="flex flex-col gap-4 max-w-[1200px] w-full max-md:px-6">
        <div className={classNames(ClassNames.GradientTitle, "text-6xl max-md:text-4xl")}>
            {title}
        </div>
        <div className={classNames(ClassNames.Text, "text-4xl max-md:text-2xl")}>
            {description}
        </div>
        <SearchSpotlight searchText={searchText} searchItems={searchItems} />
        <div className={twMerge("self-end", ClassNames.OutlinedButton, "text-brand dark:text-brand mr-8")}>
            Try Now
            {Icons.CircleRightArrow}
        </div>
    </div>
</div>
}