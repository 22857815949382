import classNames from "classnames";
import { FC } from "react";
import { CallToActionButton } from "../../components/button";
import { ClassNames } from "../../components/classes";
import { Footer } from "../../components/footer";
import { Header } from "../../components/header";
import { NewsLetterSignUp } from "../../components/news-letter";
import { Page } from "../../components/page";
import { IPanelSelectorProps, PanelSelector } from "../../components/panel-selector";
import { ScrollArrow } from "../../components/scroll-arrow";
import { BriefSection } from "../sections";
import { GraphSphere } from "./graph-sphere";
import { SearchSection } from "./search";

const nodes = [
    "GitHub",
    "GitLab",
    "Bitbucket",
    "DockerHub",
    "CI/CD",
    "Webhooks",
    "Automation",
    "Pipeline",
    "Build",
    "Deploy",
    "Email Notifications",
    "Version Control",
    "Parallel Execution",
    "Conditional Logic (If/Else)",
    "Custom Scripts",
    "Cloud Providers (AWS, GCP, Azure)",
    "Trigger Events",
    "Workflow Scheduler",
].map(label => ({
    label,
    icon: "⚙",
}));

const panels: IPanelSelectorProps["panels"] = [
    {
        label: "Connect To Version Control",
        description: "Easily connect to GitHub, GitLab, or Bitbucket for repository integration, pull requests, commenting, releases, and more.",
        url: "/demos/products/quick-container/any-language.mp4",
    },
    {
        label: "Run Flows Parallely",
        description: "Simultaneously run workflows for multiple clients with customized images and configurations—scalable and efficient.",
        url: "/demos/products/quick-container/scale-resources.mp4",
    },
    {
        label: "Update Deployment",
        description: "Seamlessly deploy the latest version of your application with automated updates triggered by image pushes.",
        url: "/demos/products/quick-container/actionable-logs.mp4",
    },
    {
        label: "Email Updates",
        description: "Send automatic email alerts when builds fail, complete with error traces for debugging by your team.",
        url: "/demos/products/quick-container/version-control.mp4",
    },
];

const searchItems = [
    
    "GitHub",
    "GitLab",
    "Bitbucket",
    "DockerHub",
    "Email",
    "Dynamic Messaging",
    "Suffix",
    "Prefix",
    "Auto Semver Increment",
    "Parallell Processing",
    "Scaling",
    "Conditional Handling",
];

export const HooksProductPage: FC = () => {
    return (
        <Page>
            <Header />
            <GraphSphere nodes={nodes} />
            <div className="flex items-center justify-center h-[100vh]">
                <div className="flex flex-col gap-4 text-center max-md:px-8">
                    <div className={classNames(ClassNames.GradientTitle, "text-6xl max-md:text-4xl")}>Hooks</div>
                    <div className={classNames(ClassNames.Text, "text-4xl max-md:text-2xl")}>
                        No-code Infrastructure Management
                    </div>
                    <CallToActionButton label="Try Now" />
                    <ScrollArrow />
                </div>
            </div>
            <BriefSection
                title="Deploy your applications in seconds."
                description="Deploy your applications quite simply in the easiest way. It is the way you can get started right away in no time. This text should be enough long to look nice. A little more. Just a little bit more."
                image1="/demos/products/hooks/setup.png"
                image2="/demos/products/hooks/run.png" />
            <div className="flex items-center justify-center md:h-[100vh] max-md:px-6">
                <div className="flex flex-col gap-16 relative">
                    <div
                        className={classNames(
                            ClassNames.GradientTitle,
                            "text-6xl max-md:text-4xl"
                        )}
                    >
                        Hook <br /> Things Together
                    </div>
                    <PanelSelector panels={panels} />
                </div>
            </div>
            <SearchSection title="Deploy Public Images"
                        description="Check for all modules you can put together"
                        searchText="Search for modules"
                        searchItems={searchItems} />
            <NewsLetterSignUp />
            <Footer />
        </Page>
    );
};
