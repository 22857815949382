import classNames from "classnames";
import { FC } from "react";
import { CallToActionButton } from "../../components/button";
import { ClassNames } from "../../components/classes";
import { Footer } from "../../components/footer";
import { Header } from "../../components/header";
import { NewsLetterSignUp } from "../../components/news-letter";
import { Page } from "../../components/page";
import { IPanelSelectorProps, PanelSelector } from "../../components/panel-selector";
import { ScrollArrow } from "../../components/scroll-arrow";
import { BriefSection } from "../sections";
import { GraphSphere } from "./graph-sphere";
import { SearchSection } from "./search";

const nodes = [
    "Pulse Sync",
    "Bright Track",
    "Eco Sphere",
    "Quick Link",
    "Life Dock",
    "Nova Planner",
    "Vivid Vault",
    "Breeze Fit",
    "Zenith Connect",
    "Momentum 360",
    "Harmoni Cloud",
    "Sleek Sync",
    "Daily Planner",
    "Task Flow",
    "Health Buddy",
    "Mind Space",
    "Photo Stream",
    "Smart Connect",
    "Travel Guide",
    "Budget Tracker",
    "Study Portal",
    "Game Lounge",
    "Recipe Saver",
    "Parent Portal",
    "Event Manager",
    "Fit Focus",
    "Ride Partner",
    "Weather Watch",
    "News Now",
    "Time Keeper",
    "Dream Journal",
    "Safe Space",
    "Work Manager",
    "Mindful Moments",
    "Cloud Sync",
    "Task Master",
    "Travel Ease",
    "Shop Smart",
    "Study Buddy",
    "Code Stream",
    "Event Planner",
    "Fit Pulse",
    "Smart Home",
    "Photo Vault",
    "News Sphere",
    "Daily Quotes",
    "Weather Alerts",
    "Eco Tracker",
    "Work Flow",
    "Focus Keeper"
].map(label => ({
    label,
    icon: "❉",
}));

const panels: IPanelSelectorProps["panels"] = [
    {
        label: "Deploy Any Language",
        description: "Deploy frontend or backend or ML applications quickly",
        url: "/demos/products/quick-container/any-language.mp4",
    },
    {
        label: "Scale Instantly",
        description: "Good news! you have too many users to cater towards? Clidey Quick Containers can scale easily up to hundreds of RAM.",
        url: "/demos/products/quick-container/scale-resources.mp4",
    },
    {
        label: "Actionable Logs",
        description: "Check why your deployment migth be failing right away. From Out of Memory issues to warn logs, you can check application logs.",
        url: "/demos/products/quick-container/actionable-logs.mp4",
    },
    {
        label: "Rollback Tension-Free",
        description: "Deploy on a Friday and have a good night, Clidey can easily rollback with a simple dropdown or use Hooks to do it automatically.",
        url: "/demos/products/quick-container/version-control.mp4",
    },
];

const images = [
    "alpine",
    "ubuntu",
    "debian",
    "nginx",
    "mysql",
    "postgres",
    "redis",
    "mongo",
    "httpd",
    "node",
    "python",
    "java",
    "golang",
    "ruby",
    "php",
    "openjdk",
    "wordpress",
    "elasticsearch",
    "kibana",
    "logstash",
    "prometheus",
    "grafana",
    "jenkins",
    "gitlab",
    "docker",
    "busybox",
    "traefik",
    "memcached",
    "rabbitmq",
    "mariadb",
    "phpmyadmin",
    "vault",
    "fluentd",
    "sonarqube",
    "influxdb",
    "postgresql",
    "haproxy",
    "consul",
    "zookeeper",
    "cassandra",
    "keycloak",
    "kafka",
    "gradle",
    "ansible",
    "tensorflow",
    "pytorch",
    "cuda",
    "spark",
    "hadoop",
    "grafana-loki"
]; 

export const QuickContainerProductPage: FC = () => {
    return <Page>
        <Header />
        <GraphSphere nodes={nodes} />
        <div className="flex items-center justify-center h-[100vh]">
            <div className="flex flex-col gap-4 text-center max-md:px-8">
                <div className={classNames(ClassNames.GradientTitle, "text-6xl max-md:text-4xl")}>
                    Quick Container
                </div>
                <div className={classNames(ClassNames.Text, "text-4xl max-md:text-2xl")}>
                    Easiest way to deploy your app
                </div>
                <CallToActionButton label="Try Now" />
                <ScrollArrow />
            </div>
        </div>
        <BriefSection
            title="Deploy your applications in seconds."
            description="Deploy your applications quite simply in the easiest way. It is the way you can get started right away in no time. This text should be enough long to look nice. A little more. Just a little bit more."
            image1="/demos/products/quick-container/create.png"
            image2="/demos/products/quick-container/explore.png" />
        <div className="flex items-center justify-center md:h-[100vh] max-md:px-6">
            <div className="flex flex-col gap-16 relative">
                <div className={classNames(ClassNames.GradientTitle, "text-6xl max-md:text-4xl")}>
                    Deploy Any Service
                </div>
                <PanelSelector panels={panels} />
            </div>
        </div>
        <SearchSection title="Deploy Public Images"
            description="Easily deploy images from public DockerHub"
            searchText="Search most popular public images"
            searchItems={images} />
        <NewsLetterSignUp />
        <Footer />
    </Page>
}