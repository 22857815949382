import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { cloneElement, FC, ReactElement, useCallback, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useScreenSize } from "../common/functions";
import { ClassNames } from "./classes";
import { Icons } from "./icons";
import { IInternalRoute, InternalRoutes } from "./routes";

type IHeaderNavBarProps = {
    label: string;
    sections: {
        section: string;
        options: {
            icon: ReactElement;
            label: string;
            description: string;
            url: IInternalRoute;
        }[];
    }[];
}

const HeaderNavBar: FC<IHeaderNavBarProps> = (props) => {
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);
    const { isMobile } = useScreenSize();
    const timeout = useRef<NodeJS.Timeout>();

    const handleMouseEnter = useCallback(() => {
        if (timeout.current != null) {
            clearInterval(timeout.current);
            timeout.current = undefined;
        }
        setHover(true);
    }, []);

    const handleMouseLeave = useCallback(() => {
        timeout.current = setTimeout(() => setHover(false), 150);
    }, []);

    const animate = hover ? "hide" : "show";

    const headerSubOptions = useMemo(() => (<>
        {
            hover &&
            <motion.div className={twMerge(classNames("absolute top-2 md:mt-8 -left-4 z-[200]", {
                    "bg-[#191919]/80": !isMobile,
                    "fixed w-[100vw] top-1/5 left-0 bottom-0 h-[calc(5*100vh/6)] rounded-b-none backdrop-blur-2xl rounded-3xl": isMobile,
                }))}
                initial={isMobile ? { opacity: 0, y: window.innerHeight/2 } : undefined}
                animate={isMobile ? { opacity: 1, y: 0 } : undefined}
                exit={isMobile ? { opacity: 0, y: window.innerHeight/2 } : undefined}
                transition={{ type: "spring", damping: 20, stiffness: 200 }}>
                <div className={twMerge(classNames(ClassNames.Card, "flex gap-4 p-6 flex-row max-md:flex-col max-md:h-full max-md:justify-center max-md:w-full rounded-lg max-md:rounded-3xl max-md:rounded-b-none"))}>
                    {
                        props.sections.map((section, i) => (
                            <div key={`section-${props.label}-${i}`} className="flex flex-col gap-2 md:w-[250px] max-md:w-full">
                                <div className={classNames(ClassNames.Title, "text-lg mb-2", ClassNames.Underline)}>
                                    {section.section}
                                </div>
                                <div className="flex flex-col gap-6">
                                    {
                                        section.options.map((option, j) => (
                                            <div key={`section-option-${props.label}-${i}-${j}`} className="flex gap-3 cursor-pointer transition-all hover:gap-4" onClick={() => {
                                                navigate(option.url.path);
                                                navigate(0);
                                            }}>
                                                {cloneElement(option.icon, {
                                                    className: classNames("w-6 h-6", ClassNames.Text),
                                                })}
                                                <div className="flex flex-col gap-2">
                                                    <div className={classNames(ClassNames.Text, "text-sm font-semibold")}>{option.label}</div>
                                                    <div className={classNames(ClassNames.Text, "text-sm")}>{option.description}</div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
                {
                    isMobile &&
                    <div className={classNames("absolute bottom-4 left-1/2 -translate-x-1/2 p-4", ClassNames.Text)} onClick={(e) => {
                        e.stopPropagation();
                        setHover(false);
                    }}>
                        {cloneElement(Icons.Cancel, {
                            className: "w-12 h-12",
                        })}
                    </div>
                }
            </motion.div>
        }
    </>), [hover, isMobile, navigate, props.label, props.sections]);

    return <div className="relative cursor-default" onMouseEnter={isMobile ? undefined : handleMouseEnter} onMouseLeave={isMobile ? undefined : handleMouseLeave} onClick={handleMouseEnter}>
        <div className="flex gap-1 items-center">
            <span className={twMerge(classNames(ClassNames.Title, "max-md:text-2xl text-lg"))}>{props.label}</span>
            <motion.div initial="show" variants={{
                show: {
                    rotate: "0deg",
                },
                hide: {
                    rotate: "180deg",
                }
            }} animate={animate} transition={{
                duration: 0.3,
            }}>
                {cloneElement(Icons.DownCaret, {
                    className: classNames("w-3 h-3", ClassNames.Text),
                })}
            </motion.div>
            {
                isMobile
                ? createPortal(headerSubOptions, document.querySelector(`.${ClassNames.AppContainer}`)!)
                : headerSubOptions
            }
        </div>
    </div>
}

const HeaderOptions: FC<{ open: boolean, onClose: () => void }> = ({ open, onClose }) => {
    const { isMobile } = useScreenSize();
    const navigate = useNavigate();

    return <AnimatePresence mode="wait">
        { (!isMobile || open) &&
            <motion.div className={twMerge(classNames("flex items-center gap-4", {
            "fixed top-6 left-6 rounded-3xl shadow h-[calc(100vh-48px)] bg-[rgba(0,0,0,0.02)] dark:bg-[rgba(255,255,255,0.02)] flex flex-col w-[calc(100vw-48px)] backdrop-blur-2xl z-[100] max-md:justify-center": open,
            "max-md:hidden": !open,
            "w-[100vw] top-1/5 left-0 bottom-0 h-[calc(5*100vh/6)] rounded-3xl rounded-b-none": isMobile,
        }))} initial={isMobile ? { opacity: 0, y: window.innerHeight/2 } : undefined}
            animate={isMobile ? { opacity: 1, y: 0 } : undefined}
            exit={isMobile ? { opacity: 0, y: window.innerHeight/2 } : undefined}
            transition={{ type: "spring", damping: 20, stiffness: 200 }}>
            <div className="flex items-center gap-2 cursor-pointer md:hidden mb-16" onClick={() => navigate(InternalRoutes.Dashboard.path)}>
                <img className="h-9" src="/logo.svg" alt="logo" />
                <div className={classNames(ClassNames.Title, "text-2xl")}>Clidey</div>
            </div>
            <HeaderNavBar label="Products" sections={[
                {
                    section: "Deployments",
                    options: [
                        {
                            icon: Icons.Deploy.QuickContainer.Default,
                            label: "Quick Containers",
                            description: "Quick deploy services in any programming langauge",
                            url: InternalRoutes.Products.QuickContainer,
                        },
                        {
                            icon: Icons.Deploy.QuickContainer.Default,
                            label: "Clusters",
                            description: "Deploy applications in any cloud provider",
                            url: InternalRoutes.Products.Clusters,
                        },
                    ],
                },
                {
                    section: "CI/CD",
                    options: [
                        {
                            icon: Icons.CI_CD.Flow.Default,
                            label: "Flows",
                            description: "Build docker images quickly with Graph based experience",
                            url: InternalRoutes.Products.Flows,
                        },
                        {
                            icon: Icons.CI_CD.Hooks.Default,
                            label: "Hooks",
                            description: "Connect your Git Push to builds & releases.",
                            url: InternalRoutes.Products.Hooks,
                        },
                    ],
                },
                {
                    section: "Databases",
                    options: [
                        {
                            icon: <img className="w-8 h-8" src="/images/whodb-logo.png" alt="WhoDB logo" />,
                            label: "WhoDB",
                            description: "Explore major databases with our open-source technology",
                            url: InternalRoutes.Products.WhoDB,
                        },
                    ],
                },
            ]} />
            <HeaderNavBar label="Use Cases" sections={[
                {
                    section: "Self Hosting",
                    options: [
                        {
                            icon: Icons.Deploy.QuickContainer.Default,
                            label: "Supabase",
                            description: "Deploy SupaBase - open source FireBase",
                            url: InternalRoutes.UseCases.Supabase,
                        },
                        // {
                        //     icon: Icons.Deploy.QuickContainer.Default,
                        //     label: "Coolify",
                        //     description: "Deploy Coolify to manage services",
                        //     url: InternalRoutes.UseCases.Coolify,
                        // },
                        // {
                        //     icon: Icons.Deploy.QuickContainer.Default,
                        //     label: "Ghost",
                        //     description: "Deploy your own blog platform",
                        //     url: InternalRoutes.UseCases.Ghost,
                        // },
                    ]
                },
                {
                    section: "Databases",
                    options: [
                        {
                            icon: Icons.Deploy.QuickContainer.Default,
                            label: "Postgres",
                            description: "Deploy Postgres instance and scale as you go",
                            url: InternalRoutes.UseCases.Postgres,
                        },
                        {
                            icon: Icons.Deploy.QuickContainer.Default,
                            label: "MongoDB",
                            description: "Deploy MongoDB instance and scale as you go",
                            url: InternalRoutes.UseCases.MongoDB,
                        },
                        {
                            icon: Icons.Deploy.QuickContainer.Default,
                            label: "MySQL",
                            description: "Deploy MySQL instance and scale as you go",
                            url: InternalRoutes.UseCases.MySQL,
                        },
                    ]
                },
                // {
                //     section: "Side Hustle",
                //     options: [
                //         {
                //             icon: Icons.Deploy.QuickContainer.Default,
                //             label: "MERN Stack",
                //             description: "Deploy your MERN stack services",
                //             url: InternalRoutes.UseCases.MERNStack,
                //         },
                //         {
                //             icon: Icons.Deploy.QuickContainer.Default,
                //             label: "LAMP Stack",
                //             description: "Deploy your LAMP stack services",
                //             url: InternalRoutes.UseCases.LAMPStack,
                //         },
                //         {
                //             icon: Icons.Deploy.QuickContainer.Default,
                //             label: "MEAN Stack",
                //             description: "Deploy your MEAN stack services",
                //             url: InternalRoutes.UseCases.MEANStack,
                //         },
                //     ]
                // },
            ]} />
            {/* <HeaderNavBar label="Examples" sections={[
                {
                    section: "Micro Services",
                    options: [
                        {
                            icon: Icons.Logos.YouTube,
                            label: "YouTube",
                            description: "Check how you can deploy YouTube in hours",
                            url: InternalRoutes.Dashboard,
                        },
                        {
                            icon: Icons.Deploy.QuickContainer.Default,
                            label: "Todo Example",
                            description: "Get started with a Todo example quickly",
                            url: InternalRoutes.Dashboard,
                        },
                    ]
                },
            ]} /> */}
            <a className={classNames(ClassNames.Title, "md:ml-4 max-md:text-2xl text-lg")} href="/pricing">
                Pricing
            </a>
            {/* <a className={classNames(ClassNames.Title, "max-md:text-3xl text-xl")} href="/docs">
                Docs
            </a> */}
            <a className={classNames(ClassNames.Title, "flex items-center gap-1 max-md:text-2xl text-lg")} href="https://blog.clidey.com/" target="_blank" rel="noreferrer">
                Blog
                {Icons.RightArrowUp}
            </a>
            {
                isMobile &&
                <div className={classNames("absolute bottom-4 left-1/2 -translate-x-1/2 p-4", ClassNames.Text)} onClick={onClose}>
                    {cloneElement(Icons.Cancel, {
                        className: "w-12 h-12",
                    })}
                </div>
            }
        </motion.div>}
    </AnimatePresence>
}

export const Header: FC = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { isMobile } = useScreenSize();

    return <div className="fixed top-0 left-0 w-full h-[120px] flex justify-center items-center p-8 md:px-16 backdrop-blur-lg z-10">
        <div className="flex justify-between w-full max-w-[1200px]">
            <div className="flex items-center gap-4">
                <div className="flex items-center gap-2 mr-16 cursor-pointer" onClick={() => navigate(InternalRoutes.Dashboard.path)}>
                    <img className="h-7" src="/logo.svg" alt="logo" />
                    <div className={classNames(ClassNames.Title, "text-2xl")}>Clidey</div>
                </div>
                {
                    isMobile
                    ? createPortal(<HeaderOptions open={open} onClose={() => setOpen(false)} />, document.querySelector(`.${ClassNames.AppContainer}`)!)
                    : <HeaderOptions open={open} onClose={() => setOpen(false)} />
                }
            </div>
            <div className={classNames("hidden max-md:flex py-4 pl-4", ClassNames.Text)} onClick={() => setOpen(true)}>
                {Icons.Menu}
            </div>
            <div className="flex gap-2 items-center max-md:hidden">
                <div className={twMerge(classNames(ClassNames.Button, ClassNames.Text, "px-8 text-xl"))} onClick={() => window.open("https://hello.clidey.com", "_blank")}>
                    Login
                    {cloneElement(Icons.CircleRightArrow, { className: "w-5 h-5 opacity-60 group-hover/button:opacity-100" })}
                </div>
                <div className={twMerge(classNames(ClassNames.Button, ClassNames.Text, "px-8 text-xl text-brand dark:text-brand"))} onClick={() => window.open("https://hello.clidey.com", "_blank")}>
                    Sign Up
                    {cloneElement(Icons.CircleRightArrow, { className: "w-5 h-5 opacity-60 group-hover/button:opacity-100" })}
                </div>
            </div>
        </div>
    </div>
}