import { FC } from "react";
import { Header } from "../../components/header";
import { Page } from "../../components/page";
import { Tutorial } from "./tutorial";

export const CoolifyUseCase: FC = () => {
    return <Page>
        <Header />
        <Tutorial
            title="Coolify"
            description="A self-hosted platform for app deployment and management"
            baseImage="/demos/use-cases/supabase/base.png"
            steps={[
                {
                    title: "Deploy Coolify",
                    description: "Launch Coolify, a platform that helps you deploy your applications with ease using Docker.",
                    url: "/demos/use-cases/coolify/coolify-deploy.mp4",
                    extras: {
                        image: "coolify/coolify:v1.0.0",
                        environmentVariables: {
                            COOlIFY_API_KEY: "your-api-key",
                        },
                        ports: "3000",
                    },
                },
                {
                    title: "Set Up Coolify Database",
                    description: "Configure a PostgreSQL or MySQL database to store application data securely.",
                    url: "/demos/use-cases/coolify/coolify-db.mp4",
                    extras: {
                        image: "postgres:13",
                        environmentVariables: {
                            POSTGRES_USER: "coolify_admin",
                            POSTGRES_PASSWORD: "securepassword123",
                            POSTGRES_DB: "coolify_db",
                        },
                        ports: "5432",
                    },
                },
                {
                    title: "Deploy Your First Application",
                    description: "Deploy your app using Coolify, connect it to the database, and start managing deployments.",
                    url: "/demos/use-cases/coolify/coolify-app.mp4",
                    extras: {},
                },
            ]}
        />
    </Page>
}
