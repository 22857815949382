import classNames from "classnames";
import { FC, ReactNode } from "react";
import { ClassNames } from "../components/classes";
import { CallToActionButton } from "../components/button";
import { ScrollArrow } from "../components/scroll-arrow";
import { twMerge } from "tailwind-merge";

export const MainSection: FC<{ title: string, description: string, image1: string, image1ClassName?: string, callToAction?: string, callToActionLink?: string, children?: ReactNode }> = ({ title, description, image1, image1ClassName, callToAction, callToActionLink, children }) => {
    return <div className="flex md:h-[100vh] w-[100vw] justify-center items-center max-md:mt-[25vh]">
        <div className="flex items-center justify-center md:h-full gap-8 relative max-w-[1200px] max-md:flex-col">
            <div className="flex flex-col gap-4 max-md:px-6 flex-1">
                <div className={classNames(ClassNames.GradientTitle, "text-6xl max-md:text-5xl")}>
                    {title}
                </div>
                <div className={classNames(ClassNames.Text, "text-4xl max-md:text-2xl")}>
                    {description}
                </div>
                <div className="flex gap-2">
                    <CallToActionButton className="self-start" label={callToAction ?? "Deploy Now"} link={callToActionLink} />
                    {children}
                </div>
            </div>
            <div className={classNames(ClassNames.TranslucentCard, "h-[550px] flex-1 max-md:rounded-xl rounded-r-none max-md:h-[350px] max-md:w-[calc(100vw-48px)]")}>
                <img className={twMerge(classNames("h-full w-full object-cover object-center", image1ClassName))} src={image1} alt="demo" />
            </div>
            <ScrollArrow />
        </div>
    </div>
}

export const BriefSection: FC<{ title: string, description: string, image1: string, image2: string }> = ({ title, description, image1, image2 }) => {
    return <div className="md:h-[100vh] w-full flex justify-center items-center max-md:px-6 max-md:py-[10vh]">
        <div className="flex flex-col gap-16 w-full max-w-[1200px]">
            <div className={classNames(ClassNames.GradientTitle, "text-6xl max-md:text-5xl md:max-w-[50vw]")}>
                {title}
            </div>
            <div className="flex gap-4 w-full max-md:flex-col md:h-[60vh]">
                <div className="flex flex-col gap-4 justify-between">
                    <div className={classNames(ClassNames.GradientText, "h-full w-[350px] flex gap-2 text-3xl max-md:text-2xl")}>
                        {description}
                    </div>
                    <div className={classNames(ClassNames.TranslucentCard, "h-full w-[350px]")}>
                        <img className="h-full max-md:min-h-[550px] w-full object-cover object-left-top" src={image1} alt="create" />
                    </div>
                </div>
                <div className={classNames(ClassNames.TranslucentCard, "h-full w-full max-md:h-[200px] flex-1")}>
                    <img className="h-full w-full object-cover object-left-top" src={image2} alt="explore" />
                </div>
            </div>
        </div>
    </div>
}