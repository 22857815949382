import { FC } from "react";
import { Header } from "../../components/header";
import { Page } from "../../components/page";
import { Tutorial } from "./tutorial";

export const MERNStackUseCase: FC = () => {
    return <Page>
        <Header />
        <Tutorial
            title="MERN Stack"
            description="Deploy a full-stack app with MongoDB, Express, React, and Node.js"
            baseImage="/demos/use-cases/supabase/base.png"
            steps={[
                {
                    title: "Set Up MongoDB",
                    description: "Launch a MongoDB instance as the backend for your app.",
                    url: "/demos/use-cases/mern/mern-mongodb.mp4",
                    extras: {
                        image: "mongo:latest",
                        environmentVariables: {
                            MONGO_INITDB_ROOT_USERNAME: "root",
                            MONGO_INITDB_ROOT_PASSWORD: "rootpassword",
                        },
                        ports: "27017",
                    },
                },
                {
                    title: "Deploy Express Backend",
                    description: "Set up Express to serve the API for your app.",
                    url: "/demos/use-cases/mern/mern-express.mp4",
                    extras: {
                        image: "node:16",
                        environmentVariables: {},
                        ports: "5000",
                    },
                },
                {
                    title: "Launch React Frontend",
                    description: "Deploy React as the frontend of your application.",
                    url: "/demos/use-cases/mern/mern-react.mp4",
                    extras: {
                        image: "node:16",
                        environmentVariables: {},
                        ports: "3000",
                    },
                },
            ]}
        />
    </Page>
}
