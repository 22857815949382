import { values } from "lodash";
import { ReactNode } from "react";
import { PricingPage } from "../pages/pricing";
import { PrivacyPolicy } from "../pages/privacy-policy";
import { QuickContainerProductPage } from "../pages/products/quick-container";
import { TermsOfService } from "../pages/terms-of-service";
import { DashboardPage } from "../pages/dashboard";
import { ClusterProductPage } from "../pages/products/cluster";
import { SupaBaseUseCase } from "../pages/use-cases/supabase";
import { CoolifyUseCase } from "../pages/use-cases/coolify";
import { GhostUseCase } from "../pages/use-cases/ghost";
import { PostgresUseCase } from "../pages/use-cases/postgres";
import { MongoDBUseCase } from "../pages/use-cases/mongodb";
import { MySQLUseCase } from "../pages/use-cases/mysql";
import { MERNStackUseCase } from "../pages/use-cases/mern";
import { LAMPStackUseCase } from "../pages/use-cases/lamp";
import { MEANStackUseCase } from "../pages/use-cases/mean";
import { FlowProductPage } from "../pages/products/flows";
import { HooksProductPage } from "../pages/products/hooks";
import { WhoDBProductPage } from "../pages/products/whodb";

export type IInternalRoute = {
    name: string;
    path: string;
    component: ReactNode;
}

export const InternalRoutes = {
    Dashboard: {
        name: "Home",
        path: "/",
        component: <DashboardPage />,
    },
    Products: {
        QuickContainer: {
            name: "Quick Container",
            path: "/products/quick-container",
            component: <QuickContainerProductPage />,
        },
        Clusters: {
            name: "Clusters",
            path: "/products/clusters",
            component: <ClusterProductPage />,
        },
        Flows: {
            name: "Flows",
            path: "/products/flows",
            component: <FlowProductPage />,
        },
        Hooks: {
            name: "Hooks",
            path: "/products/hooks",
            component: <HooksProductPage />,
        },
        WhoDB: {
            name: "WhoDB",
            path: "/products/whodb",
            component: <WhoDBProductPage />,
        },
    },
    UseCases: {
        Supabase: {
            name: "Supabase",
            path: "/use-cases/supabase",
            component: <SupaBaseUseCase />,
        },
        Coolify: {
            name: "Coolify",
            path: "/use-cases/coolify",
            component: <CoolifyUseCase />,
        },
        Ghost: {
            name: "Ghost",
            path: "/use-cases/ghost",
            component: <GhostUseCase />,
        },
        Postgres: {
            name: "PostgreSQL",
            path: "/use-cases/postgres",
            component: <PostgresUseCase />,
        },
        MongoDB: {
            name: "MongoDB",
            path: "/use-cases/mongodb",
            component: <MongoDBUseCase />,
        },
        MySQL: {
            name: "MySQL",
            path: "/use-cases/mysql",
            component: <MySQLUseCase />,
        },
        MERNStack: {
            name: "MERN Stack",
            path: "/use-cases/mern-stack",
            component: <MERNStackUseCase />,
        },
        LAMPStack: {
            name: "LAMP Stack",
            path: "/use-cases/lamp-stack",
            component: <LAMPStackUseCase />,
        },
        MEANStack: {
            name: "MEAN Stack",
            path: "/use-cases/mean-stack",
            component: <MEANStackUseCase />,
        },
    },
    Pricing: {
        name: "Pricing",
        path: "/pricing",
        component: <PricingPage />,
    },
    Legal: {
        TermsOfService: {
            name: "Terms of Service",
            path: "/terms-of-service",
            component: <TermsOfService />
        },
        PrivacyPolicy: {
            name: "Privacy Policy",
            path: "/privacy-policy",
            component: <PrivacyPolicy />
        },
    },
};

export const getRoutes = (): IInternalRoute[] => {
    const allRoutes: IInternalRoute[] = [];
    const currentRoutes = values(InternalRoutes);
    while (currentRoutes.length > 0) {
        const currentRoute = currentRoutes.shift();
        if (currentRoute == null) {
            continue;
        }
        if ("path" in currentRoute) {
            allRoutes.push(currentRoute);
            continue;
        }
        currentRoutes.push(...values(currentRoute));
    }
    return allRoutes;
}