import { FC } from "react";
import { Header } from "../../components/header";
import { Page } from "../../components/page";
import { Tutorial } from "./tutorial";

export const PostgresUseCase: FC = () => {
    return <Page>
        <Header />
        <Tutorial
            title="PostgreSQL"
            description="Deploy a reliable PostgreSQL database"
            baseImage="/demos/use-cases/postgres/base.png"
            steps={[
                {
                    title: "Deploy PostgreSQL",
                    description: "Launch a PostgreSQL instance for storing data for your applications.",
                    url: "/demos/use-cases/postgres/postgres.mp4",
                    extras: {
                        image: "postgres:15.2.0",
                        environmentVariables: {
                            POSTGRES_USER: "admin",
                            POSTGRES_PASSWORD: "securepassword",
                            POSTGRES_DB: "app_db",
                        },
                        ports: "5432",
                    },
                },
                {
                    title: "Deploy the Application",
                    description: "Launch the application that interacts with the Postgres database. This service serves the frontend or API for user interaction.",
                    actionLabel: "Try Now",
                    extras: {},
                },
            ]}
        />
    </Page>
}
