import classNames from "classnames";
import { FC } from "react";
import { CallToActionButton } from "../../components/button";
import { ClassNames } from "../../components/classes";
import { Footer } from "../../components/footer";
import { Header } from "../../components/header";
import { NewsLetterSignUp } from "../../components/news-letter";
import { Page } from "../../components/page";
import { IPanelSelectorProps, PanelSelector } from "../../components/panel-selector";
import { ScrollArrow } from "../../components/scroll-arrow";
import { BriefSection } from "../sections";
import { GraphSphere } from "./graph-sphere";
import { SearchSection } from "./search";

const nodes = [
    "Git Pull",
    "Git Checkout",
    "Git Merge",
    "Branch Create",
    "Docker Build",
    "Docker Push",
    "Docker Pull",
    "Docker Run",
    "Docker Stop",
    "Docker Remove",
    "Docker Tag",
    "Image Prune",
    "Container Prune",
    "Pipeline Deploy",
    "Pipeline Trigger",
    "Code Lint",
    "Code Format",
    "Unit Test",
    "Integration Test",
    "Functional Test",
    "Static Analysis",
    "Build Package",
    "Artifact Upload",
    "Artifact Download",
    "Environment Setup",
    "Configuration Check",
    "Version Bump",
    "Release Tag",
    "Build Image",
    "Run Container",
    "Deploy App",
    "Rollback",
    "Health Check",
    "Logs Tail",
    "Metrics Fetch",
    "Secrets Fetch",
    "Cache Clear",
    "Database Migrate",
    "Database Seed",
    "Queue Process",
    "Queue Purge",
    "Restart Service",
    "Stop Service",
    "Start Service",
    "Monitor Metrics",
    "Clean Workspace",
    "Run Tests",
    "Deploy Rollout"
].map(label => ({
    label,
    icon: "⚙",
}));

const panels: IPanelSelectorProps["panels"] = [
    {
        label: "Package Anything",
        description: "Use docker builds to test, compile, and package any application in any language.",
        url: "/demos/products/flows/any-package.mp4",
    },
    {
        label: "Versioning",
        description: "Flows can easily push to Docker registry with a suffix or prefix to segregate versions. It will also auto pick up Semver version from previous push.",
        url: "/demos/products/flows/prefix-suffix.mp4",
    },
    {
        label: "Scale Instantly",
        description: "Good news! you have too many builds happening? Clidey Flows can only be run in parallel but can be scaled to hundreds of GB in memory.",
        url: "/demos/products/flows/scale-resources.mp4",
    },
    {
        label: "Actionable debugging",
        description: "Easily find logs of the build processes to quickly perform actions and get the application packaged.",
        url: "/demos/products/flows/debug.mp4",
    },
];

const images = [
    "alpine",
    "ubuntu",
    "debian",
    "nginx",
    "mysql",
    "postgres",
    "redis",
    "mongo",
    "httpd",
    "node",
    "python",
    "java",
    "golang",
    "ruby",
    "php",
    "openjdk",
    "wordpress",
    "elasticsearch",
    "kibana",
    "logstash",
    "prometheus",
    "grafana",
    "jenkins",
    "gitlab",
    "docker",
    "busybox",
    "traefik",
    "memcached",
    "rabbitmq",
    "mariadb",
    "phpmyadmin",
    "vault",
    "fluentd",
    "sonarqube",
    "influxdb",
    "postgresql",
    "haproxy",
    "consul",
    "zookeeper",
    "cassandra",
    "keycloak",
    "kafka",
    "gradle",
    "ansible",
    "tensorflow",
    "pytorch",
    "cuda",
    "spark",
    "hadoop",
    "grafana-loki"
];  

export const FlowProductPage: FC = () => {
    return <Page>
        <Header />
        <GraphSphere nodes={nodes} />
        <div className="flex items-center justify-center h-[100vh]">
            <div className="flex flex-col gap-4 text-center max-md:px-8">
                <div className={classNames(ClassNames.GradientTitle, "text-6xl max-md:text-4xl")}>
                    Flow
                </div>
                <div className={classNames(ClassNames.Text, "text-4xl max-md:text-2xl")}>
                    CI/CD Redefined With No-code Builder.
                </div>
                <CallToActionButton label="Try Now" />
                <ScrollArrow />
            </div>
        </div>
        <BriefSection
            title="No-Code Application Packager"
            description="Flows are visual, graph-based tools that let you design deployment pipelines. Simply connect your version control and registries, and watch your application come to life."
            image1="/demos/products/flows/logs.png"
            image2="/demos/products/flows/no-code-build.png" />
        <div className="flex items-center justify-center md:h-[100vh] max-md:px-6">
            <div className="flex flex-col gap-16 relative">
                <div className={classNames(ClassNames.GradientTitle, "text-6xl max-md:text-4xl")}>
                    Build <br /> Any Application
                </div>
                <PanelSelector panels={panels} />
            </div>
        </div>
        <SearchSection title="Build Any Image"
                    description="Easily start with public images to build on"
                    searchText="Search most popular public images"
                    searchItems={images} />
        <NewsLetterSignUp />
        <Footer />
    </Page>
}