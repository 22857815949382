import { FC } from "react";
import { Header } from "../../components/header";
import { Page } from "../../components/page";
import { Tutorial } from "./tutorial";

export const SupaBaseUseCase: FC = () => {
    return <Page>
        <Header />
        <Tutorial
            title="Supabase"
            description="Fastest way to self-host Supabase"
            baseImage="/demos/use-cases/supabase/base.png"
            steps={[
            {
                title: "Deploy Supabase Studio for Management",
                description: "Launch Supabase Studio, a web-based tool to manage your database, authentication, storage, and other Supabase services. It provides a friendly user interface for administrative tasks.",
                url: "/demos/use-cases/supabase/supabase-studio.mp4",
                extras: {
                    image: "supabase/studio:v1.0.0",
                    environmentVariables: {
                        SUPABASE_URL: "http://localhost:3000",
                        SUPABASE_KEY: "super-secure-api-key"
                    },
                    ports: "3000"
                }
            },
            {
                title: "Deploy the Supabase Postgres Database",
                description: "Set up a PostgreSQL instance as the core database for your Supabase application. This service will handle all data storage needs with robust reliability and scalability.",
                url: "/demos/use-cases/supabase/supabase-postgres.mp4",
                extras: {
                    image: "supabase/postgres:15.2.0",
                    volumes: "/var/lib/postgresql/data",
                    environmentVariables: {
                        POSTGRES_USER: "supabase_admin",
                        POSTGRES_PASSWORD: "securepassword123",
                        POSTGRES_DB: "todo_app"
                    },
                    ports: "5432"
                }
            },
            {
                title: "Add Supabase Storage for File Management",
                description: "Set up Supabase's storage service to manage user-uploaded files, such as images, documents, or any other assets. It provides secure and scalable object storage.",
                url: "/demos/use-cases/supabase/supabase-storage.mp4",
                extras: {
                    image: "supabase/storage:v1.0.0",
                    volumes: "/var/lib/supabase/storage",
                    environmentVariables: {
                        SUPABASE_URL: "http://localhost:8000",
                        SUPABASE_KEY: "super-secure-api-key",
                        PGRST_JWT_SECRET: "super-secret-jwt-secret",
                        DATABASE_URL: "http://localhost:5432",
                    },
                }
            },
            {
                title: "Integrate Realtime Database Updates",
                description: "Enable Supabase's realtime service to listen for database changes and push updates to connected clients. This allows for live updates in your application.",
                url: "/demos/use-cases/supabase/supabase-realtime.mp4",
                extras: {
                    image: "supabase/realtime:v1.0.0",
                    environmentVariables: {
                        SUPABASE_URL: "http://localhost:8000",
                        SUPABASE_KEY: "super-secure-api-key",
                    },
                }
            },
            {
                title: "Deploy the Application",
                description: "Launch the application that interacts with the Supabase backend to manage tasks. This service serves the frontend or API for user interaction.",
                actionLabel: "Try Now",
                extras: {},
            },
        ]} />
    </Page>
}