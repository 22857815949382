import { Text } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import { useScroll, useTime, useTransform } from "framer-motion";
import { isArray } from "lodash";
import { FC, useMemo, useRef } from "react";
import { Group, Mesh, MeshStandardMaterial, Vector3 } from "three";

// Generate evenly distributed positions on a sphere's surface using the Fibonacci method
const getSpherePositions = (radius: number, numDivs: number) => {
    const positions: Vector3[] = [];
    const phiFactor = Math.PI * (3 - Math.sqrt(5)); // Golden angle in radians

    for (let i = 0; i < numDivs; i++) {
        const y = 1 - (i / (numDivs - 1)) * 2; // Range [-1, 1]
        const radiusAtY = Math.sqrt(1 - y * y); // Radius at given y
        const theta = phiFactor * i; // Angle
        const x = radiusAtY * Math.cos(theta);
        const z = radiusAtY * Math.sin(theta);

        positions.push(new Vector3(x * radius, y * radius, z * radius + 0.5));
    }

    return positions;
};

type IGraphSphere = {
    nodes: {
        label: string;
        icon: string;
    }[];
};

const GraphCard: FC<{
    position: Vector3;
    node: { label: string; icon: string };
}> = ({ position, node }) => {
    const ref = useRef<Group>(null);
    const rectangleRef = useRef<MeshStandardMaterial>(null);
    const textRef = useRef<Mesh>(null);
    const { scrollYProgress } = useScroll(); // Get scroll progress (0 to 1)
    const opacity = useTransform(scrollYProgress, [0, 0.25, 1], [1, 0.25, 0.15]);
    const distance = useTransform(scrollYProgress, [0, 0.7, 1], [30, 10, 10]);
    const xRotation = useTransform(scrollYProgress, [0, 1], [Math.PI / 2, -Math.PI / 2]); // Map scroll progress to rotation (0 to 90 degrees)
    const time = useTime();

    useFrame(({ camera }) => {
        if (ref.current == null || textRef.current == null || rectangleRef.current == null) {
            return;
        }
        camera.position.setFromSphericalCoords(
            distance.get(),
            time.get() * 0.000025,
            xRotation.get(),
        );
        camera.updateProjectionMatrix();
        camera.lookAt(0, 0, 0);
        ref.current.quaternion.copy(camera.quaternion);
        if (!isArray(textRef.current.material) && !isArray(rectangleRef.current)) {
            textRef.current.material.opacity = opacity.get(); // Apply the text opacity here
            rectangleRef.current.opacity = opacity.get(); // Apply the text opacity here
        }
    });

    return (
        <group ref={ref} position={position.toArray()}>
            <mesh>
                <planeGeometry args={[1.5, 0.5]} />
                <meshStandardMaterial ref={rectangleRef} color="#6b7280" opacity={0.5} transparent />
            </mesh>
            <Text
                ref={textRef}
                fontWeight={200}
                fontSize={0.1}
                color="#6b7280"
                anchorX="center"
                anchorY="middle"
                rotation={[0, 0, 0]}>
                {node.icon}
                &nbsp;&nbsp;
                {node.label}
            </Text>
        </group>
    );
};


const radius = 8;
const numDivs = 100;

export const GraphSphere: FC<IGraphSphere> = ({ nodes }) => {
    const positions = useMemo(() => getSpherePositions(radius, numDivs), []);

    return (
        <Canvas
            className="!fixed top-0 left-0 w-full !h-[200vh] !pointer-events-none -z-20"
            camera={{ position: [0, 0, 10], fov: 75 }}
        >
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} />

            {positions.map((position, index) => (
                <GraphCard
                    key={index}
                    position={position}
                    node={nodes[index % nodes.length]}
                />
            ))}
        </Canvas>
    );
};
