import { FC } from "react";
import { Header } from "../../components/header";
import { Page } from "../../components/page";
import { Tutorial } from "./tutorial";

export const MEANStackUseCase: FC = () => {
    return <Page>
        <Header />
        <Tutorial
            title="MEAN Stack"
            description="Set up a full-stack application using MongoDB, Express, Angular, and Node.js"
            baseImage="/demos/use-cases/supabase/base.png"
            steps={[
                {
                    title: "Set Up MongoDB",
                    description: "Launch MongoDB as your database for storing data.",
                    url: "/demos/use-cases/mean/mean-mongodb.mp4",
                    extras: {
                        image: "mongo:latest",
                        environmentVariables: {
                            MONGO_INITDB_ROOT_USERNAME: "root",
                            MONGO_INITDB_ROOT_PASSWORD: "rootpassword",
                        },
                        ports: "27017",
                    },
                },
                {
                    title: "Deploy Express Backend",
                    description: "Set up Express to manage the backend of your application.",
                    url: "/demos/use-cases/mean/mean-express.mp4",
                    extras: {
                        image: "node:16",
                        environmentVariables: {},
                        ports: "5000",
                    },
                },
                {
                    title: "Launch Angular Frontend",
                    description: "Deploy Angular as the frontend of your application.",
                    url: "/demos/use-cases/mean/mean-angular.mp4",
                    extras: {
                        image: "node:16",
                        environmentVariables: {},
                        ports: "4200",
                    },
                },
            ]}
        />
    </Page>
}
