import { map } from "lodash";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getRoutes } from "./components/routes";
import classNames from "classnames";
import { ClassNames } from "./components/classes";

function App() {
  return (
    <div className={classNames(ClassNames.AppContainer, "dark overflow-x-hidden")}>
      <BrowserRouter>
        <Routes>
            {map(getRoutes(), route => (
              <Route key={route.path} path={route.path} element={route.component} />
            ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
