import { useState, useEffect, useRef, FC, cloneElement } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { Icons } from "./icons";

export const LazyVideo: FC<{ src: string; className?: string }> = ({ src, className }) => {
  const [isInView, setIsInView] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsInView(entry.isIntersecting),
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const handleDoubleTap = () => {
    setIsFullscreen(true);
  };

  const handleTap = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <motion.div
      className="relative w-full h-full group/video"
      ref={containerRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: isInView ? 1 : 0 }}
      transition={{ duration: 0.5 }}
    >
      {isInView ? (
        <motion.div
          className={twMerge(classNames("relative w-full h-full overflow-hidden", className))}
          onTap={handleTap}
          onDoubleClick={handleDoubleTap}
          layout
          animate={isFullscreen ? { 
            top: 0, 
            left: 0, 
            width: "100vw", 
            height: "100vh", 
            position: "fixed", 
            zIndex: 50 
          } : {}}
          transition={{ duration: 0.3 }}
        >
          <video
            ref={videoRef}
            className="rounded-2xl w-full h-full object-cover"
            autoPlay
            loop
            muted
            playsInline
            onLoadedData={() => setIsVideoLoaded(true)}
          >
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <AnimatePresence mode="wait">
            {isPlaying ? (
              <motion.div
                key="pause"
                className="absolute inset-0 flex items-center justify-center hover:bg-black hover:bg-opacity-20 rounded-2xl pointer-events-none"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <div className="opacity-0 group-hover/video:opacity-100 transition-all">
                  {cloneElement(Icons.Paused, { className: "w-12 h-12" })}
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="play"
                className="absolute inset-0 flex items-center justify-center hover:bg-black hover:bg-opacity-20 rounded-2xl pointer-events-none"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <div className="opacity-0 group-hover/video:opacity-100 transition-all">
                  {cloneElement(Icons.Play, { className: "w-12 h-12" })}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence mode="sync">
            {isFullscreen && (
              <motion.button
                className="fixed bottom-4 left-1/2 -translate-y-1/2 p-4 rounded-full z-[100]"
                onClick={() => setIsFullscreen(false)}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
              >
                {cloneElement(Icons.Cancel, {
                  className: "w-12 h-12",
                })}
              </motion.button>
            )}
          </AnimatePresence>
        </motion.div>
      ) : (
        <div className="h-full w-full rounded-lg bg-neutral-200 dark:bg-neutral-800 animate-pulse" />
      )}

      {!isVideoLoaded && isInView && (
        <div className="absolute top-0 h-full w-full rounded-lg bg-neutral-200 dark:bg-neutral-800 animate-pulse" />
      )}
    </motion.div>
  );
};
