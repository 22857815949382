import { Html, Text3D } from '@react-three/drei';
import { Canvas, useFrame, useThree, Vector3 } from '@react-three/fiber';
import classNames from 'classnames';
import { useScroll, useTime, useTransform } from 'framer-motion';
import { entries } from 'lodash';
import { FC, ReactNode, useRef } from 'react';
import { Mesh } from 'three';
import { useScreenSize } from '../../common/functions';
import { CallToActionButton } from '../../components/button';
import { ClassNames } from '../../components/classes';
import { Footer } from '../../components/footer';
import { Icons } from '../../components/icons';
import { LazyVideo } from '../../components/lazy-video';
import { NewsLetterSignUp } from '../../components/news-letter';
import { GalaxyScene } from '../dashboard';
import { MainSection } from '../sections';

export const AnimatedThreeDText: FC<{ stepNumber: ReactNode, totalSteps: number }> = ({ stepNumber, totalSteps }) => {
    const { isMobile } = useScreenSize();
    const ref = useRef<Mesh>(null);
    const { scrollYProgress } = useScroll();
    const baseYRotation = useTransform(scrollYProgress, [0, 1], [0, Math.PI/4]);
    const time = useTime();
    const { height: h, width: w } = useThree((state) => state.viewport);
    
    useFrame(() => {
        if (ref.current == null) return;
        const baseRotation = baseYRotation.get();
        const timeFactor = Math.sin(time.get() * 0.0001);
        const dynamicRotation = baseRotation + timeFactor * (-Math.PI / 6);
        ref.current.rotation.y = dynamicRotation;
    });

    const size = (isMobile ? (h/6) : (h/2))/totalSteps;
    const offset = stepNumber === 1 ? isMobile ? 0.05 : 0.35 : 0;

    return <Text3D
        ref={ref}
        font="/fonts/megrim.typeface.json"
        size={size}
        height={0.01}
        curveSegments={32}
        bevelEnabled
        bevelThickness={0.1}
        bevelSize={0.02}
        bevelSegments={10}
        position={isMobile ? [-size+w/2 + offset, 0, (1/totalSteps)*-2] : [-size + offset, -size, 0]}>
        {stepNumber}
        <meshStandardMaterial color="#6b7280" wireframe />
    </Text3D>
}


export const ThreeDText: FC<{ stepNumber: number, step: ITutorialProps["steps"][0], totalSteps: number }> = ({ stepNumber, step, totalSteps }) => {
    const { isMobile } = useScreenSize();
    const { height, width } = useThree().viewport;
    const scale = height/totalSteps;
    const textPadding = width*0.1;
    const position: Vector3 = [isMobile ? -width/2 + textPadding : 0, -1/totalSteps, 0];
    const contentWidth = isMobile ? window.outerWidth - window.outerWidth*0.2 : window.outerWidth/2;

    return (
        <group position={[isMobile ? 0 : -0.5, (height/2)-(stepNumber*scale) - 1/totalSteps - (isMobile ? 0.5 : 0.25), 0]}>
            <AnimatedThreeDText stepNumber={stepNumber+1} totalSteps={totalSteps} />
            <Html position={position}
                className="flex flex-col justify-center gap-4 md:-mt-16"
                zIndexRange={[0, -1]} style={{
                    width: contentWidth,
                }}>
                <div className={classNames(ClassNames.Title, "text-4xl max-md:text-3xl")}>
                    {step.title}
                </div>
                <div className={classNames(ClassNames.Text, "text-base max-md:text-sm")}>
                    {step.description}
                </div>
                <div className={classNames(ClassNames.Text, "text-base max-md:text-sm flex gap-2 items-center", {
                    "hidden": step.extras.volumes == null,
                })}>
                    {Icons.CircleRightArrow} Volumes: {step.extras.volumes}
                </div>
                <div className={classNames(ClassNames.Text, "text-base max-md:text-sm flex flex-col gap-2 justify-end", {
                    "hidden": step.extras.environmentVariables == null,
                })}>
                    <div className="flex gap-2 items-center">
                        {Icons.CircleRightArrow} EnvironmentVariables:
                    </div>
                    {entries(step.extras.environmentVariables ?? {}).map(([key, value]) => (
                        <div className="flex gap-2 items-center md:ml-12 max-md:ml-4">
                            {key}={value}
                        </div>
                    ))}
                </div>
                <div className={classNames(ClassNames.Text, "text-base max-md:text-sm flex gap-2 items-center", {
                    "hidden": step.extras.ports == null,
                })}>
                    {Icons.CircleRightArrow} Port: {step.extras.ports}
                </div>
                {step.url != null && <div className="md:h-[450px] w-full object-cover rounded-2xl overflow-hidden" style={{
                    width: contentWidth,
                }}>
                    <LazyVideo src={step.url} />
                </div>}
                <CallToActionButton className="self-start h-[50px] w-[350px] max-md:self-center" label={step.actionLabel ?? "Deploy service"} />
            </Html>
        </group>
    );
};

type ITutorialProps = {
    title: string;
    description: string;
    baseImage: string;
    baseImageClassName?: string;
    steps: {
        title: string;
        description: string;
        actionLabel?: string;
        url?: string;
        extras: {
            image?: string;
            volumes?: string;
            environmentVariables?: Record<string, string>;
            ports?: string;
        },
    }[];
}

export const Tutorial: FC<ITutorialProps> = ({ title, description, baseImage, baseImageClassName, steps }) => {
    return (
        <>
            <Canvas className="!fixed top-0 left-0 w-full !h-[180vh] !pointer-events-none -z-10"
                camera={{ position: [4, 2, 5], fov: 50 }}>
                <GalaxyScene />
            </Canvas>
            <div className="flex flex-col">
                <MainSection title={title} description={description} image1={baseImage} />
                <div className="flex flex-col max-w-[1200px] max-md:px-[10vw] self-center md:gap-32">
                    {
                        steps.map((step, index) => (
                            <div key={`use-case-${index}`} className="flex gap-2 max-md:flex-col md:gap-16">
                                <div className={classNames(ClassNames.GradientTitle, "text-[512px] max-md:text-[248px] opacity-50 max-md:self-center md:min-w-[300px] md:text-end flex justify-end md:-mt-44")}>
                                    {index+1}
                                </div>
                                <div className="flex flex-col gap-4 flex-1">
                                    <div className={classNames(ClassNames.GradientTitle, "text-4xl max-md:text-3xl")}>
                                        {step.title}
                                    </div>
                                    <div className={classNames(ClassNames.Text, "text-base max-md:text-sm max-w-[450px]")}>
                                        {step.description}
                                    </div>
                                    <div className={classNames(ClassNames.Text, "text-base max-md:text-sm flex gap-2 items-center", {
                                        "hidden": step.extras.volumes == null,
                                    })}>
                                        {Icons.CircleRightArrow} Volumes: {step.extras.volumes}
                                    </div>
                                    <div className={classNames(ClassNames.Text, "text-base max-md:text-sm flex flex-col gap-2 justify-end", {
                                        "hidden": step.extras.environmentVariables == null,
                                    })}>
                                        <div className="flex gap-2 items-center">
                                            {Icons.CircleRightArrow} EnvironmentVariables:
                                        </div>
                                        {entries(step.extras.environmentVariables ?? {}).map(([key, value]) => (
                                            <div className="flex gap-2 items-center md:ml-12 max-md:ml-4">
                                                {key}={value}
                                            </div>
                                        ))}
                                    </div>
                                    <div className={classNames(ClassNames.Text, "text-base max-md:text-sm flex gap-2 items-center", {
                                        "hidden": step.extras.ports == null,
                                    })}>
                                        {Icons.CircleRightArrow} Port: {step.extras.ports}
                                    </div>
                                    {step.url != null && <div className="md:h-[450px] w-full object-cover rounded-2xl overflow-hidden">
                                        <LazyVideo src={step.url} />
                                    </div>}
                                    <CallToActionButton className="self-start h-[50px] w-[350px] max-md:self-center" label={step.actionLabel ?? "Deploy service"} />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="flex justify-center items-center mt-[10vh] py-[10vh] backdrop-blur-md">
                <NewsLetterSignUp />    
            </div>
            <Footer />
        </>
    );
};
