import classNames from "classnames";
import jsonp from "jsonp";
import { ChangeEvent, FC, KeyboardEvent, ReactElement, useCallback, useState } from "react";
import { twMerge } from "tailwind-merge";
import { ClassNames } from "./classes";
import { Icons } from "./icons";

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

type ISignupFormProps = {
  header?: ReactElement;
  title?: ReactElement;
  description?: ReactElement;
}

export const NewsLetterSignUp: FC<ISignupFormProps> = (props) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [value, setValue] = useState("");
  const handleSubmit = useCallback(() => {
    if (!validateEmail(value)) {
      setError("Email is not valid");
      return;
    }
    jsonp(`https://clidey.us21.list-manage.com/subscribe/post-json?u=11174af68a4e9c1c5794959ad&id=f18b336972&c=&f_id=0053e4e6f0&b_11174af68a4e9c1c5794959ad_f18b336972=&` + new URLSearchParams({
      EMAIL: value,
    }), {
      param: "c",
    }, (err, data) => {
      if (err != null) {
        setError(data.msg);
        return;
      }

      setSuccess("Your email has been added! ✅");
      setTimeout(() => setSuccess(null), 5000);
      
      setError(null);
      setValue("");
    });
  }, [value]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setValue(e.target.value);
  }, []);

  const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      return handleSubmit();
    }
  }, [handleSubmit]);

  return (
    <div className="flex w-full justify-center items-center">
        <div className="flex flex-col w-full max-w-[600px] gap-4 max-md:px-6">
            <div className={classNames(ClassNames.GradientTitle, "text-5xl max-md:text-4xl w-[50vw]")}>
                Want to stay updated?
            </div>
            <input className={ClassNames.Input} placeholder="Enter your email" value={value} onChange={handleChange} onKeyDown={handleKeyDown} />
            <div className={classNames("flex items-center", {
                "justify-between": error != null || success != null,
                "justify-end": error == null && success == null,
            })}>
                {error != null && <div className={classNames(ClassNames.Text, "text-lg max-md:text-base text-red-500 dark:text-red-500")}>
                    {error}
                </div>}
                {success != null && <div className={classNames(ClassNames.Text, "text-lg max-md:text-base text-green-500 dark:text-green-500")}>
                    {success}
                </div>}
                <div className={twMerge(classNames(ClassNames.OutlinedButton, "px-8 py-2 text-brand dark:text-brand rounded-full"))} onClick={handleSubmit}>
                    Submit
                    {Icons.CircleRightArrow}
                </div>
            </div>
        </div>
    </div>
  )
}