import { cloneElement, FC } from "react";
import { ClassNames } from "./classes";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { Icons } from "./icons";

export const CallToActionButton: FC<{ className?: string, label: string, onClick?: () => void, link?: string }> = ({ className, label, onClick, link }) => {
    return <div className={twMerge(classNames(ClassNames.OutlinedButton, "relative overflow-hidden self-center text-2xl pl-4 border-dashed border-brand/20 dark:border-brand/20 py-4 text-brand dark:text-brand w-full max-w-[250px] group/deploy-button hover:pl-6", className))}
        onClick={onClick ?? (() => window.open(link ?? "https://hello.clidey.com", "_blank"))}>
        <div className={"text-transparent bg-clip-text bg-gradient-to-r from-brand to-brand/60"}>
            {label}
        </div>
        <div className={classNames(ClassNames.Text, "absolute right-0 top-0 px-4 h-full bg-brand flex justify-center items-center")}>
            {cloneElement(Icons.CircleRightArrow, {
                className: "w-4 h-4 transition-all group-hover/deploy-button:scale-150",
            })}
        </div>
    </div>
}