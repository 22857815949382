import { FC } from "react";
import { Header } from "../../components/header";
import { Page } from "../../components/page";
import { Tutorial } from "./tutorial";

export const MySQLUseCase: FC = () => {
    return <Page>
        <Header />
        <Tutorial
            title="MySQL"
            description="Deploy a reliable MySQL database for your applications"
            baseImage="/demos/use-cases/mysql/base.svg"
            steps={[
                {
                    title: "Deploy MySQL",
                    description: "Launch MySQL to store relational data for your app.",
                    url: "/demos/use-cases/mysql/mysql-deploy.mp4",
                    extras: {
                        image: "mysql:latest",
                        environmentVariables: {
                            MYSQL_ROOT_PASSWORD: "rootpassword",
                            MYSQL_DATABASE: "app_db",
                        },
                        ports: "3306",
                    },
                },
                {
                    title: "Deploy the Application",
                    description: "Launch the application that interacts with the Postgres database. This service serves the frontend or API for user interaction.",
                    actionLabel: "Try Now",
                    extras: {},
                },
            ]}
        />
    </Page>
}
