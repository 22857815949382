import { FC } from "react";
import { Header } from "../../components/header";
import { Page } from "../../components/page";
import { Tutorial } from "./tutorial";

export const GhostUseCase: FC = () => {
    return <Page>
        <Header />
        <Tutorial
            title="Ghost"
            description="A simple and powerful open-source publishing platform"
            baseImage="/demos/use-cases/supabase/base.png"
            steps={[
                {
                    title: "Deploy Ghost",
                    description: "Set up Ghost for a blog or content management website.",
                    url: "/demos/use-cases/ghost/ghost-deploy.mp4",
                    extras: {
                        image: "ghost:latest",
                        environmentVariables: {
                            GHOST_URL: "http://localhost:2368",
                            GHOST_ADMIN_API_KEY: "admin-api-key",
                        },
                        ports: "2368",
                    },
                },
                {
                    title: "Set Up Ghost Database",
                    description: "Configure the database for storing your Ghost data (PostgreSQL).",
                    url: "/demos/use-cases/ghost/ghost-db.mp4",
                    extras: {
                        image: "postgres:13",
                        environmentVariables: {
                            POSTGRES_USER: "ghost_admin",
                            POSTGRES_PASSWORD: "ghostpassword",
                            POSTGRES_DB: "ghost_db",
                        },
                        ports: "5432",
                    },
                },
            ]}
        />
    </Page>
}
