import { OrbitControls } from "@react-three/drei";
import { Canvas, useFrame } from '@react-three/fiber';
import classNames from "classnames";
import { useScroll, useTime, useTransform } from "framer-motion";
import { cloneElement, FC, useMemo } from "react";
import { CallToActionButton } from "../components/button";
import { ClassNames } from "../components/classes";
import { Footer } from "../components/footer";
import { Header } from "../components/header";
import { Icons } from "../components/icons";
import { NewsLetterSignUp } from "../components/news-letter";
import { Page } from "../components/page";
import { MainSection } from "./sections";

const CuboidBuildings = () => {
    const { scrollYProgress } = useScroll(); // Get scroll progress (0 to 1)
    const baseYRotation = useTransform(scrollYProgress, [0, 1], [-Math.PI / 3, -Math.PI/2]); // Map scroll progress to rotation
    const distance = useTransform(scrollYProgress, [0, 0.2, 1], [120, 70, 40]);
    const time = useTime();

    const buildings = useMemo(() => {
        const numRows = 20;
        const numCols = 50;
        const spacing = 2;

        const newBuildings = [];
        for (let row = 0; row < numRows; row++) {
            for (let col = 0; col < numCols; col++) {
                const height = (row + 1) * Math.random() * 2 + 1; // Taller in the back
                const x = col * spacing - (numCols * spacing) / 2;
                const z = row * spacing - (numRows * spacing) / 2;
                const y = height / 2; // Center cuboid on Y-axis
                newBuildings.push({ x, y, z, height });
            }
        }
        return newBuildings;
    }, []);

    useFrame(({ camera }) => {
        const baseRotation = baseYRotation.get();
        const timeFactor = Math.sin(time.get() * 0.000025); // Oscillate over time
        const dynamicRotation = baseRotation + timeFactor * (-Math.PI / 6); // Map sine to -π/6 to π/6 range

        camera.position.setFromSphericalCoords(
            distance.get(),
            dynamicRotation,
            0,
        );
        camera.updateProjectionMatrix();
        camera.lookAt(0, 0, 0);
    });

    return (
        <>
            {buildings.map((building, index) => (
                <mesh key={index} position={[building.x, building.y, building.z]}>
                    <boxGeometry args={[1, building.height, 1]} />
                    <meshStandardMaterial color="#6b7280" wireframe />
                </mesh>
            ))}
        </>
    );
}

const Scene = () => {
    return (
        <Canvas className="!fixed top-0 left-0 w-full !pointer-events-none !h-[200vh] -z-20 blur-[1px] brightness-[0.35]" camera={{ fov: 75 }}>
        <ambientLight intensity={2} />
        <directionalLight position={[5, 10, 5]} intensity={8} />
        <CuboidBuildings />
        <OrbitControls />
        </Canvas>
    );
}


const tiers = [
    {
        name: "Starter",
        description: "For personal projects, prototypes, and getting started.",
        features: [
            "Two-week trial and no credit card required",
            "1 User",
            "Fast, no-code automation for builds & deployments",
            "Visual workflow editor (Up to 2 workflows)",
            "Infrastructure visualization",
            "Up to 5 deployments/month",
            "Manage up to 5 environment variables",
            "1 Custom domain",
            "Resource allocation: 250m CPU, 3.5GB RAM",
            "Instant rollbacks & live previews",
            "Security & secret management",
        ],
        price: 10,
        hasTrial: true,
        addons: [
            "Additional workflows & automation triggers",
            "Extra deployments",
            "Extra volumes",
        ],
    },
    {
        name: "Startup",
        description: "For growing teams managing multiple applications.",
        features: [
            "Everything in Starter",
            "Unlimited environment variables",
            "Up to 10 deployments/month",
            "Up to 5 workflows & 5 automation triggers",
            "Scale up to 3 domains",
            "Persistent storage (10GB included)",
            "1 Managed cloud cluster",
            "Resource allocation: 500m CPU, 6GB RAM",
        ],
        price: 25,
        popular: true,
        addons: [
            "Additional workflows & automation triggers",
            "Extra deployments",
            "Extra volumes",
        ],
    },
    {
        name: "Professional",
        description: "For scaling teams that need advanced automation and flexibility.",
        features: [
           "Everything in Startup",
            "Unlimited domains",
            "20 workflows & automation triggers",
            "Up to 20 deployments/month",
            "Connect up to 3 external cloud clusters",
            "Advanced monitoring & logs",
            "Higher resource allocation: 1000m CPU, 12GB RAM",
            "Team collaboration tools",
        ],
        price: 35,
        addons: [
            "Additional workflows & automation triggers",
            "Extra deployments",
            "Extra volumes",
        ],
    },
    {
        name: "Enterprise",
        description: "For organizations needing scalability, security, and custom solutions.",
        features: [
            "Everything in Professional",
            "Unlimited workflows & deployments",
            "Unlimited clusters & integrations",
            "Team permissions & role-based access",
            "24/7 dedicated support",
            "Custom security, SSO, and compliance features",
            "Flexible billing options",
        ],
        addons: [
            "Advanced analytics & monitoring",
            "Custom security & compliance upgrades",
        ]
    }
];

export const PricingPage: FC = () => {
    return <Page>
        <Header />
        <Scene />
        <MainSection title="Get started for free" description="From Side Hustle to Enterprise, we are here for you." image1="/images/pricing.png" image1ClassName="object-left-top" />
        <div className="flex flex-col justify-center items-center gap-16 max-md:px-6 max-md:mt-[10vh]">
            <div className="flex flex-col gap-2 max-w-[1200px] w-full">
                <div className={classNames(ClassNames.GradientTitle, "text-5xl max-md:text-4xl")}>
                    Customized Tiers
                </div>
                <div className={classNames(ClassNames.GradientText, "text-2xl max-md:text-xl md:w-[50vw]")}>
                    Clidey makes managing infrastructure, automation, and deployments effortless—without the need for complex coding. Choose a plan that fits your needs.
                </div>
            </div>
            <div className="flex justify-center items-center">
                <div className="flex md:justify-center max-md:flex-col flex-wrap gap-4 md:w-[75vw]">
                    {tiers.map(tier => (
                        <div className="flex flex-col gap-16 border border-black/10 dark:border-white/10 rounded-xl p-8 backdrop-blur-md w-full md:max-w-[350px]">
                            <div className="flex flex-col gap-4">
                                <div className="flex justify-between items-center">
                                    <div className={classNames(ClassNames.GradientTitle, "text-4xl max-md:text-2xl")}>
                                        {tier.name}
                                    </div>
                                    { tier.popular && <div className={classNames(ClassNames.Text, "bg-teal-400 dark:bg-teal-800 rounded-xl px-2 text-sm")}>
                                        Popular
                                    </div>}
                                </div>
                                <div className={classNames(ClassNames.GradientText, "text-lg")}>
                                    {tier.description}
                                </div>
                                <div className={classNames(ClassNames.Text, "text-2xl mt-4")}>
                                    {tier.price == null ? "Contact Sales" : `$${tier.price}/month`}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className={classNames(ClassNames.Text, "text-lg")}>What's included?</div>
                                {tier.features.map(feature => (
                                    <div className={classNames("flex gap-2", ClassNames.Text)}>
                                        {cloneElement(Icons.CheckCircle, {
                                            className: "w-4 h-4 mt-1",
                                        })}
                                        <div className="text-base">
                                            {feature}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex flex-col gap-2 grow justify-end">
                                <div className={classNames(ClassNames.Text, "text-lg")}>Add-ons</div>
                                {tier.addons.map(addon => (
                                    <div className={classNames("flex gap-2", ClassNames.Text)}>
                                        {cloneElement(Icons.CheckBadge, {
                                            className: "w-4 h-4 mt-1",
                                        })}
                                        <div className="text-base">
                                            {addon}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex">
                                <CallToActionButton className="self-end max-w-full" label={tier.price == null ? "Contact Sales" : tier.hasTrial ? "Start For Free" : "Choose"} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <div className="flex justify-center items-center mt-[10vh] py-[10vh] backdrop-blur-md">
            <NewsLetterSignUp />    
        </div>
        <Footer />
    </Page>
}