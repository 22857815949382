import classNames from "classnames";
import { FC } from "react";
import { CallToActionButton } from "../../components/button";
import { ClassNames } from "../../components/classes";
import { Footer } from "../../components/footer";
import { Header } from "../../components/header";
import { NewsLetterSignUp } from "../../components/news-letter";
import { Page } from "../../components/page";
import { IPanelSelectorProps, PanelSelector } from "../../components/panel-selector";
import { ScrollArrow } from "../../components/scroll-arrow";
import { BriefSection } from "../sections";
import { GraphSphere } from "./graph-sphere";
import { SearchSection } from "./search";

const nodes = [
    "Compute Instances",
    "Virtual Machines (VMs)",
    "Kubernetes",
    "Serverless",
    "Containerization",
    "Object Storage",
    "Block Storage",
    "Networking",
    "Load Balancers",
    "Content Delivery Networks (CDNs)",
    "Databases",
    "Relational Databases",
    "NoSQL Databases",
    "Database as a Service (DBaaS)",
    "Machine Learning",
    "AI Services",
    "Big Data",
    "Analytics",
    "Identity and Access Management (IAM)",
    "Cloud Security",
    "Monitoring",
    "Logging",
    "DevOps",
    "Infrastructure as Code (IaC)",
    "Terraform",
    "CI/CD Pipelines",
    "Hybrid Cloud",
    "Multi-Cloud",
    "Edge Computing",
    "Cloud Functions",
    "API Gateways",
    "VPC (Virtual Private Cloud)",
    "Private Cloud",
    "Elasticity",
    "Scalability",
    "Disaster Recovery",
    "Backup Solutions",
    "Data Migration",
    "Cloud Native",
    "Microservices",
    "Service Mesh",
    "Managed Services",
    "Pricing Models",
    "Pay-as-you-go",
    "Free Tier",
    "Regions",
    "Zones",
    "Cloud Marketplace",
    "SDKs and APIs",
].map(label => ({
    label,
    icon: "✻",
}));

const panels: IPanelSelectorProps["panels"] = [
    {
        "label": "Secure Deployments",
        "description": "Easily connect Clidey to external cloud providers, ensuring your data and deployments remain secure.",
        "url": "/demos/products/cluster/integrate.mp4"
    },
    {
        "label": "Persistent Volumes",
        "description": "Save critical data directly into your Kubernetes cluster with persistent volumes seamlessly managed by Clidey.",
        "url": "/demos/products/cluster/volumes.mp4"
    },
    {
        "label": "Domain Management",
        "description": "Effortlessly redirect domains to pods in your cluster, streamlining your application's accessibility.",
        "url": "/demos/products/cluster/domain.mp4"
    },
    {
        "label": "Regional Processing",
        "description": "Redirect processing into specific regions to ensure compliance, optimize latency, and meet data residency requirements.",
        "url": "/demos/products/cluster/region.mp4"
    },
];

const clusters = [
    "Amazon Web Services (AWS) - Amazon EKS",
    "Google Cloud Platform (GCP) - Google Kubernetes Engine (GKE)",
    "Microsoft Azure - Azure Kubernetes Service (AKS)",
    "IBM Cloud - IBM Cloud Kubernetes Service",
    "DigitalOcean - DigitalOcean Kubernetes (DOKS)",
    "Oracle Cloud - Oracle Container Engine for Kubernetes (OKE)",
    "Linode - Linode Kubernetes Engine (LKE)",
    "VMware - VMware Tanzu Kubernetes Grid",
    "Alibaba Cloud - Container Service for Kubernetes (ACK)",
    "Tencent Cloud - Tencent Kubernetes Engine (TKE)",
    "OVHcloud - Managed Kubernetes Service",
    "Hetzner Cloud - Hetzner Kubernetes Engine",
    "Scaleway - Scaleway Kubernetes Kapsule",
    "Red Hat OpenShift - OpenShift Kubernetes Engine",
    "Packet (Equinix Metal) - Kubernetes on Bare Metal"
];  


export const ClusterProductPage: FC = () => {
    return <Page>
        <Header />
        <GraphSphere nodes={nodes} />
        <div className="flex items-center justify-center h-[100vh]">
            <div className="flex flex-col gap-4 text-center px-8">
                <div className={classNames(ClassNames.GradientTitle, "text-6xl max-md:text-4xl")}>
                    Clusters
                </div>
                <div className={classNames(ClassNames.Text, "text-4xl max-md:text-2xl")}>
                    Connect to your Kubernetes Cluster
                </div>
                <CallToActionButton label="Try Now" />
                <ScrollArrow />
            </div>
        </div>
        <BriefSection
            title="Deploy to any Kubernetes cluster."
            description="Clusters connect Clidey to external cloud providers, ensuring your application and data remains secure within the cluster. With clusters, you can deploy your applications to any region, cloud provider, or on-prem. "
            image1="/demos/products/cluster/create.png"
            image2="/demos/products/cluster/deploy.png" />
        <div className="flex items-center justify-center md:h-[100vh] max-md:px-6">
            <div className="flex flex-col gap-16 relative">
                <div className={classNames(ClassNames.GradientTitle, "text-6xl max-md:text-4xl")}>
                    Connect <br /> Major Cloud Provider
                </div>
                <PanelSelector panels={panels} />
            </div>
        </div>
        <SearchSection title="Deploy To Cloud"
                    description="Easily connect to your own cloud provider to deploy"
                    searchText="Search most popular cloud providers"
                    searchItems={clusters} />
        <NewsLetterSignUp />
        <Footer />
    </Page>
}