import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { cloneElement, FC, useEffect, useState } from "react";
import { Icons } from "./icons";
import { ClassNames } from "./classes";

export const ScrollArrow: FC = () => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        setTimeout(() => setOpen(false), 3000);
    }, []);

    return <div>
        <AnimatePresence mode="wait">
            {
                open &&
                <motion.div className="fixed left-1/2 bottom-8 -translate-x-1/2 flex"
                    initial={{ y: -100, scale: 0.8, opacity: 0, }}
                    animate={{ y: 0, scale: 1, opacity: 100, }}
                    exit={{ y: 100, scale: 1.1, opacity: 0, transitionEnd: { display: "none" } }}
                    transition={{ delay: 1, repeat: 0 }}>
                    {cloneElement(Icons.DownArrowLong, {
                        className: classNames("w-8 h-8", ClassNames.Text),
                    })}
                </motion.div>
            }
        </AnimatePresence>
    </div>
}