import { Canvas, useFrame } from "@react-three/fiber";
import classNames from "classnames";
import { motion, useScroll, useTime, useTransform } from "framer-motion";
import { cloneElement, FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Edge, Node, Position, useEdgesState, useNodesState } from "reactflow";
import { twMerge } from "tailwind-merge";
import { AnimatedText } from "../components/animated";
import { ClassNames } from "../components/classes";
import { Footer } from "../components/footer";
import { Galaxy } from "../components/graphics/galaxy";
import { CustomHandle } from "../components/graphs/custom-handle";
import { Graph, IGraphCardProps, IGraphInstance } from "../components/graphs/graph";
import { Header } from "../components/header";
import { Icons } from "../components/icons";
import { NewsLetterSignUp } from "../components/news-letter";
import { Page } from "../components/page";
import { PanelSelector } from "../components/panel-selector";
import { BriefSection, MainSection } from "./sections";

export const GalaxyScene = () => {
    const { scrollYProgress } = useScroll(); // Get scroll progress (0 to 1)
    const xRotation = useTransform(scrollYProgress, [0, 1], [Math.PI / 2, 0]); // Map scroll progress to rotation (0 to 90 degrees)
    const distance = useTransform(scrollYProgress, [0, 1], [8, 24]);
    const time = useTime();
  
    useFrame(({ camera }) => {
      camera.position.setFromSphericalCoords(
        distance.get(),
        xRotation.get(),
        time.get() * 0.00005,
      );
      camera.rotation.x = xRotation.get(); // Apply rotation based on scroll
      camera.updateProjectionMatrix();
      camera.lookAt(0, 0, 0);
    });

    return (
      <>
        <ambientLight />
        <Galaxy size={0.01} colorInside="#6b7280" colorOutside="#374151" count={15000} branches={5} />
      </>
    );
}; 

const GraphNode: FC<{ icon: ReactElement, label: string }> = ({ icon, label }) => {
    return <div className="flex gap-1 items-center border dark:border-white/5 rounded-lg bg-transparent p-4">
        <CustomHandle type="target" position={Position.Left} />
        {cloneElement(icon, {
            className: classNames("w-6 h-6", ClassNames.Text),
        })}
        <div className={classNames(ClassNames.Text, "text-lg")}>
            {label}
        </div>
        <CustomHandle type="source" position={Position.Right} />
    </div>
};

const QuickContainerGraphNode: FC<IGraphCardProps> = (props) => <GraphNode icon={Icons.Deploy.QuickContainer.Default} label={props.data.label} />
const EnvironmentVariableGraphNode: FC<IGraphCardProps> = (props) => <GraphNode icon={Icons.Config.EnvironmentVariable.Default} label={props.data.label} />
const DomainGraphNode: FC<IGraphCardProps> = (props) => <GraphNode icon={Icons.Config.Domain.Default} label={props.data.label} />

const defaultNodes: Node[] = [
    { 
        id: "service-auth", 
        type: "quick-container", 
        data: { label: "Auth Service" }, 
        position: { x: 0, y: 0, }, 
    },
    { 
        id: "service-api-gateway", 
        type: "quick-container", 
        data: { label: "API Gateway" }, 
        position: { x: 0, y: 0, }, 
    },
    { 
        id: "service-business-logic", 
        type: "quick-container", 
        data: { label: "App Service" }, 
        position: { x: 0, y: 0, }, 
    },
    { 
        id: "service-postgres", 
        type: "quick-container", 
        data: { label: "Postgres DB" }, 
        position: { x: 0, y: 0, }, 
    },
    { 
        id: "service-postgres-secrets", 
        type: "environment-variable", 
        data: { label: "Postgres Secrets" }, 
        position: { x: 0, y: 0, }, 
    },
    { 
        id: "service-app-secrets", 
        type: "environment-variable", 
        data: { label: "App Secrets" }, 
        position: { x: 0, y: 0, }, 
    },
    { 
        id: "service-app-domain", 
        type: "domain", 
        data: { label: "App Domain" }, 
        position: { x: 0, y: 0, }, 
    },
];

const defaultEdges: Edge[] = [
    { 
        id: "service-auth->service-api-gateway", 
        source: "service-auth", 
        target: "service-api-gateway",  
        type: "floatingGraphEdge",
    },
    { 
        id: "service-api-gateway->service-business-logic", 
        source: "service-api-gateway", 
        target: "service-business-logic",  
        type: "floatingGraphEdge",
    },
    { 
        id: "service-business-logic->service-postgres", 
        source: "service-business-logic", 
        target: "service-postgres",  
        type: "floatingGraphEdge",
    },
    { 
        id: "service-postgres-secrets->service-postgres", 
        source: "service-postgres-secrets", 
        target: "service-postgres",  
        type: "floatingGraphEdge",
    },
    { 
        id: "service-app-secrets->service-postgres", 
        source: "service-app-secrets", 
        target: "service-postgres",  
        type: "floatingGraphEdge",
    },
    { 
        id: "service-app-domain->service-business-logic", 
        source: "service-app-domain", 
        target: "service-business-logic",
        type: "floatingGraphEdge",
    },
];


const GraphDashboard: FC = () => {
    const [nodes, setNodes, onNodesChange] = useNodesState(defaultNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(defaultEdges);

    const nodeTypes = useMemo(() => ({
        "quick-container": QuickContainerGraphNode,
        "environment-variable": EnvironmentVariableGraphNode,
        "domain": DomainGraphNode,
    }), []);

    const handleReady = useCallback((graphInstance: IGraphInstance) => {
        graphInstance.layout();
    }, []);

    return <Graph nodes={nodes}
                edges={edges}
                setNodes={setNodes}
                setEdges={setEdges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={nodeTypes}
                fitViewOptions={{
                    padding: 5,
                }}
                minZoom={0.1}
                maxZoom={1}
                onReady={handleReady} />
}
const features = [
    { 
        label: "Deploy", 
        description: "Deploy applications quickly and reliably with Clidey. Whether you need to deploy a container or scale across cloud providers, Clidey has you covered.",
        url: "/demos/dashboard/quick-container.mp4",
    },
    { 
        label: "CI/CD", 
        description: "Streamline your development lifecycle with Clidey's no-code-friendly CI/CD tools.",
        url: "/demos/dashboard/flows.mp4",
    },
    { 
        label: "Visualization", 
        description: "Understand your infrastructure better with Clidey's graph-based visualization tools.",
        url: "/demos/dashboard/visualization.mp4",
    },
    { 
        label: "Configuration", 
        description: "Effortlessly manage environment variables, secrets, and domains with Clidey's intuitive configuration tools.",
        url: "/demos/dashboard/configuration.mp4",
    },
    { 
        label: "Integrations", 
        description: "Connect with your favorite platforms like GitHub, DockerHub, and more for seamless workflows.",
        url: "/demos/dashboard/integration.mp4",
    },
];

const DashboardFeaturesSpotlight: React.FC = () => {
    return (
        <div className="flex items-center justify-center md:h-[100vh] px-6">
            <div className="flex flex-col gap-16 relative">
                <div className={classNames(ClassNames.GradientTitle, "text-6xl max-md:text-5xl")}>
                    No-Code Infra
                </div>
                <PanelSelector panels={features} />
            </div>
        </div>
    );
};

const DashboardDockerSpotlight: FC = () => {
    const [active, setActive] = useState(-1);
    const interval = useRef<NodeJS.Timeout | null>(null);
    const messages = [
        "A new registry created",
        "Image pulled from the registry",
        "Environment variables created for the deployment",
        "Deployment created with the registry image and secrets"
    ];

    useEffect(() => {
        return () => {
            if (interval.current) {
                clearInterval(interval.current);
            }
        };
    }, []);

    const handleDone = useCallback(() => {
        if (interval.current) {
            clearInterval(interval.current);
        }

        interval.current = setInterval(() => {
            setActive(prevActive => {
                const nextActive = prevActive + 1;
                if (nextActive > messages.length) {
                    if (interval.current) {
                        clearInterval(interval.current);
                    }
                    return prevActive;
                }
                return nextActive;
            });
        }, 1000);
    }, [messages.length]);

    return (
        <div className="flex flex-col justify-center md:gap-16 max-md:gap-4 md:h-[100vh] w-[100vw] max-w-[1200px] self-center max-md:px-6 max-md:my-[20vh]">
            <div className={classNames(ClassNames.GradientTitle, "text-5xl max-md:text-2xl")}>
                Deploy in seconds
            </div>
            <div className="flex gap-8 max-md:flex-col">
                <div className="flex-2 max-md:w-[calc(100vw-48px)] flex flex-col gap-2">
                    <div className="border dark:border-white/5 px-8 py-2 rounded-full mb-4">
                        <AnimatedText
                            text="docker run -e POSTGRES_USER=user -e POSTGRES_PASSWORD=password postgres"
                            onDone={handleDone}
                        />
                    </div>
                    {messages.map((message, index) => (
                        <motion.div
                            key={index}
                            className={classNames(
                                ClassNames.Text,
                                "w-full border border-white/5 rounded-full px-8 max-md:px-4 py-4 max-md:py-2 flex gap-2 items-center dark:bg-white/5"
                            )}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{
                                opacity: index <= active ? 1 : 0,
                                y: index <= active ? 0 : -20
                            }}
                            transition={{ duration: 0.5, delay: index * 0.3 }}
                        >
                            {Icons.CheckCircle}
                            <div className="text-lg max-md:text-sm">{message}</div>
                        </motion.div>
                    ))}
                    <motion.div
                        className="flex justify-end items-center"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{
                            opacity: 4 <= active ? 1 : 0,
                            y: 4 <= active ? 0 : -20
                        }}
                        transition={{ duration: 0.5, delay: 4 * 0.3 }}
                    >
                        <div className={twMerge(classNames(ClassNames.OutlinedButton, "px-8 py-2 text-brand dark:text-brand rounded-full"))}>
                            Try now
                            {Icons.CircleRightArrow}
                        </div>
                    </motion.div>
                </div>
                <div className={classNames(ClassNames.TranslucentCard, "rounded-r-none h-full flex-1")}>
                    <img className="h-full w-full object-cover object-right" src="/demos/dashboard/template.png" alt="demo" />
                </div>
            </div>
        </div>
    );
};

const DashboardSection1: FC = () => {
    return <>
        <div className="absolute inset-0 !pointer-events-none brightness-[0.25] blur-[5px] -z-10">
            <GraphDashboard />
        </div>
        <Canvas className="!fixed top-0 left-0 w-full !h-[180vh] !pointer-events-none -z-10"
            camera={{ position: [4, 2, 5], fov: 50 }}>
            <GalaxyScene />
        </Canvas>
        <div className="flex flex-col gap-16">
            <MainSection title="DevOps Simplified" description="Easiest way to deploy & manage your applications" image1="/demos/dashboard/graph.png" callToAction="Try now" />
            <BriefSection
                title="No-Code DevOps Powerhouse"
                description="Clidey revolutionizes DevOps with an intuitive, almost-no-code platform designed for seamless CI/CD, effortless deployments, real-time visualization, and powerful integrations."
                image1="/demos/dashboard/deployments.png"
                image2="/demos/dashboard/hooks.png" />
            <DashboardFeaturesSpotlight />
            <DashboardDockerSpotlight />
        </div>
        <NewsLetterSignUp />
        <Footer />
    </>
}

export const DashboardPage: FC = () => {
    return <Page>
        <Header />
        <DashboardSection1 />
    </Page>
}