import { FC } from "react";
import { Header } from "../../components/header";
import { Page } from "../../components/page";
import { Tutorial } from "./tutorial";

export const LAMPStackUseCase: FC = () => {
    return <Page>
        <Header />
        <Tutorial
            title="LAMP Stack"
            description="Deploy a full-stack LAMP application (Linux, Apache, MySQL, PHP)"
            baseImage="/demos/use-cases/supabase/base.png"
            steps={[
                {
                    title: "Deploy Apache Server",
                    description: "Set up Apache to serve the PHP application.",
                    url: "/demos/use-cases/lamp/lamp-apache.mp4",
                    extras: {
                        image: "httpd:latest",
                        ports: "80",
                    },
                },
                {
                    title: "Set Up MySQL Database",
                    description: "Launch MySQL for relational data storage.",
                    url: "/demos/use-cases/lamp/lamp-mysql.mp4",
                    extras: {
                        image: "mysql:latest",
                        environmentVariables: {
                            MYSQL_ROOT_PASSWORD: "rootpassword",
                            MYSQL_DATABASE: "lamp_db",
                        },
                        ports: "3306",
                    },
                },
                {
                    title: "Deploy PHP Application",
                    description: "Deploy your PHP application on Apache.",
                    url: "/demos/use-cases/lamp/lamp-php.mp4",
                    extras: {},
                },
            ]}
        />
    </Page>
}
