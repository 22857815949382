import { cloneElement, FC } from "react";
import { Icons } from "./icons";
import { ClassNames } from "./classes";
import classNames from "classnames";

const sections = [
    {
        label: "Social Media",
        options: [
            { label: "LinkedIn", icon: Icons.Logos.LinkedIn, },
            { label: "Facebook", icon: Icons.Logos.Facebook, },
            { label: "Instagram", icon: Icons.Logos.Instagram, },
        ],
    },
    {
        label: "Components",
        options: [
            { label: "Quick Container", icon: Icons.Deploy.QuickContainer.Default, },
            { label: "Clusters", icon: Icons.Deploy.Cluster.Default, },
            { label: "Hooks", icon: Icons.CI_CD.Hooks.Default, },
            { label: "Flows", icon: Icons.CI_CD.Flow.Default, },
            { label: "WhoDB", icon: <img src="/images/whodb-logo.png" alt="WhoDB logo" />, },
        ],
    },
    {
        label: "Use-Cases",
        options: [
            { label: "Self Hosting", icon: Icons.Deploy.QuickContainer.Default, },
            { label: "Databases", icon: Icons.CircleStacked, },
            { label: "Side Hustle", icon: Icons.Rocket, },
        ],
    },
    {
        label: "Legal",
        options: [
            { label: "Privacy Policy", icon: Icons.DocumentText, },
            { label: "Terms of Use", icon: Icons.DocumentText, },
        ],
    },
]

export const Footer: FC<{ className?: string }> = ({ className }) => {
    return <div className={classNames("w-full md:h-[50vh] flex justify-center gap-16 pt-16 mt-[10vh] max-md:flex-col max-md:px-8 max-md:gap-8 max-md:pb-16 bg-gradient-to-b from-black/5 to-black/25 backdrop-blur-sm", className)}>
        {
            sections.map((section, i) => (
                <div key={`footer-section-${i}`} className="flex flex-col gap-4">
                    <div className={classNames(ClassNames.Title, ClassNames.Underline, "pr-24 text-base")}>
                        {section.label}
                    </div>
                    {section.options.map((option, j) => (
                        <div key={`footer-section-option${i}-${j}`} className={classNames(ClassNames.Text, "text-base flex gap-2 items-center")}>
                            {cloneElement(option.icon, {
                                className: "w-5 h-5",
                            })}
                            {option.label}
                        </div>
                    ))}
                </div>
            ))
        }
    </div>
}