import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { ClassNames } from "../../components/classes";
import { Footer } from "../../components/footer";
import { Header } from "../../components/header";
import { NewsLetterSignUp } from "../../components/news-letter";
import { Page } from "../../components/page";
import { IPanelSelectorProps, PanelSelector } from "../../components/panel-selector";
import { BriefSection, MainSection } from "../sections";
import { GraphSphere } from "./graph-sphere";
import { SearchSection } from "./search";
import { CallToActionButton } from "../../components/button";

const nodes = [
    "Postgres",
    "MySQL",
    "MariaDB",
    "MongoDB",
    "OracleDB",
    "SQL Server",
    "SQLite",
    "Redis",
    "DynamoDB",
    "Elasticsearch",
    "Create Database",
    "Drop Database",
    "Backup Database",
    "Restore Database",
    "Optimize Database",
    "Query Data",
    "Indexing",
    "Normalization",
    "Denormalization",
    "Sharding",
    "Replication",
    "Partitioning",
    "Transactions",
    "Stored Procedures",
    "Views",
    "Triggers",
    "Joins",
    "Foreign Keys",
    "Primary Keys",
    "Data Warehousing",
    "ETL (Extract, Transform, Load)",
    "Data Migration",
    "Caching",
    "Full-Text Search",
    "Concurrency Control",
    "Database Security",
    "Access Control",
    "Auditing",
    "Logging",
    "Schema Design",
    "Query Optimization",
    "NoSQL Aggregations",
    "SQL Analytics",
    "Real-time Streaming",
    "Machine Learning on Databases",
    "Data Lake Integration",
].map(label => ({
    label,
    icon: "❉",
}));
const panels: IPanelSelectorProps["panels"] = [
    {
        label: "Conversate With Your Data",
        description: "No more wasting time crafting complex SQL queries - ask away!",
        url: "/demos/products/whodb/conversate.mp4",
    },
    {
        label: "Enhanced UX",
        description: "A clean, intuitive interface that's easy to navigate. Search or edit data with exceptional speed. Our table virtualization technology holds up with gigantic amounts of data. Interactive graphs to easily visualize your database schema.",
        url: "/demos/products/whodb/enhanced-ux.mp4",
    },
    {
        label: "Broad Database Support",
        description: "Compatible with PostgreSQL, MySQL, SQLite3, MongoDB, Redis, MariaDB, & ElasticSearch. WhoDB Enterprise supports many others (including Microsoft SQL Server, Oracle, and DynamoDB).",
        url: "/demos/products/whodb/broad-database-support.mp4",
    },
    {
        label: "Scratchpad",
        description: "A Jupyter notebook-like interface for performing database queries.",
        url: "/demos/products/whodb/scratchpad.mp4",
    },
];


const images = [
    "Postgres",
    "MySQL",
    "MariaDB",
    "MongoDB",
    "OracleDB",
    "SQL Server",
    "SQLite",
    "Redis",
    "DynamoDB",
    "Elasticsearch",
];
const formatStars = (count: number) => {
    if (count >= 1000) {
        return (count / 1000).toFixed(1) + 'k';
    }
    return count;
};

export const WhoDBProductPage: FC = () => {
    const [stars, setStars] = useState<string>();

    useEffect(() => {
        const fetchStars = async () => {
            try {
                const response = await fetch('https://api.github.com/repos/clidey/whodb');
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setStars(formatStars(data.stargazers_count ?? 0).toString());
            } catch (error) {
                console.error('Error fetching the GitHub stars:', error);
            }
        };

        fetchStars();
    }, []);

    return <Page>
        <Header />
        <GraphSphere nodes={nodes} />
        <MainSection title="WhoDB"
            description="An open-source lightweight next-gen data explorer"
            image1="/demos/products/whodb/introduction.png"
            callToAction={`GitHub ${stars != null ? `(${stars})` : ""}`}
            image1ClassName="object-left-top"
            callToActionLink="https://github.com/clidey/whodb">
                <CallToActionButton className="self-start" label="Try Demo"
                    link="https://qc-dde11d46-3af6-4db0-8ae6-cb33fb653871.hello.clidey.com/login?host=quick-container-491288b0-3138-48fa-93b4-1e730296c0b7.hello.svc.cluster.local&username=user&password=password&database=Adventureworks" />    
        </MainSection>

        <BriefSection
            title="Explore any major database within seconds"
            description="Connect with Postgres, MySQL, SQLite, MongoDB, Redis, MariaDB, Elastic Search, and Clickhouse. Explore your data. Edit. Update. or Chat. All inside a browser of your choice."
            image1="/demos/products/whodb/table-view.png"
            image2="/demos/products/whodb/chat-example-1.png" />
        <div className="flex items-center justify-center md:h-[100vh] max-md:px-6">
            <div className="flex flex-col gap-16 relative">
                <div className={classNames(ClassNames.Title, "text-6xl max-md:text-4xl")}>
                    First-Class Exploration
                </div>
                <PanelSelector panels={panels} />
            </div>
        </div>
        <SearchSection title="Explore Databases"
            description="Quickly spin up WhoDB with all major databases"
            searchText="Search for most popular databases"
            searchItems={images} />
        <NewsLetterSignUp />
        <Footer />
    </Page>
}