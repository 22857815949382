import React, { FC, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import classNames from 'classnames';
import { ClassNames } from './classes';

export const AnimatedText: FC<{ text: string, speed?: number, onDone?: () => void, }> = ({ text, speed = 0.05, onDone }) => {
  const letters = text.split('');
  const controls = useAnimation(); 
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref);

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [inView, controls]);

  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: speed,
      },
    },
  };

  const child = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <motion.div
      ref={ref}
      variants={container}
      initial="hidden"
      animate={controls}
      className={classNames(ClassNames.Text, "flex flex-wrap text-lg max-md:text-sm w-full")}
      onAnimationComplete={onDone}>
      {letters.map((letter, index) => (
        <motion.span className="inline-block whitespace-pre" key={index} variants={child}>
          {letter}
        </motion.span>
      ))}
    </motion.div>
  );
};
