import React, { useMemo, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

type IGalaxyProps = {
  size?: number;
  colorInside?: string;
  colorOutside?: string;
  count?: number;
  branches?: number;
  spinSpeed?: number;
}

export const Galaxy: React.FC<IGalaxyProps> = ({
  size = 0.08,
  colorInside = "#ffa575",
  colorOutside = "#311599",
  count = 20000,
  branches = 3,
  spinSpeed = 0.01,
}) => {
  const particlesRef = useRef<THREE.BufferAttribute>(null);
  const particles = useMemo(() => {
    const positions = new Float32Array(count * 3); // x, y, z for each particle
    const colors = new Float32Array(count * 3); // r, g, b for each particle
    const angles = new Float32Array(count); // Track each particle's angle
    const colorInsideVector = new THREE.Color(colorInside);
    const colorOutsideVector = new THREE.Color(colorOutside);

    for (let i = 0; i < count; i++) {
      const radius = Math.random() * 5; // Distance from center
      const branch = (i % branches) / branches; // Branch index normalized (0 to 1)
      const initialAngle = branch * Math.PI * 2 + radius; // Initial angle for each particle
      angles[i] = initialAngle; // Store the angle for animation

      const randomX = (Math.random() - 0.5) * 0.3; // Small random offset
      const randomY = (Math.random() - 0.5) * 0.3;
      const randomZ = (Math.random() - 0.5) * 0.3;

      const i3 = i * 3;
      positions[i3] = Math.cos(initialAngle) * radius + randomX; // x
      positions[i3 + 1] = randomY; // y
      positions[i3 + 2] = Math.sin(initialAngle) * radius + randomZ; // z

      const mixedColor = colorInsideVector.clone().lerp(colorOutsideVector, radius / 5);
      colors[i3] = mixedColor.r;
      colors[i3 + 1] = mixedColor.g;
      colors[i3 + 2] = mixedColor.b;
    }

    return { positions, colors, angles };
  }, [count, branches, colorInside, colorOutside]);

  useFrame(({ clock }) => {
    if (particlesRef.current) {
      const time = clock.getElapsedTime();
      const { array } = particlesRef.current;
      const { positions, angles } = particles;

      for (let i = 0; i < count; i++) {
        const radius = Math.sqrt(positions[i * 3] ** 2 + positions[i * 3 + 2] ** 2); // Radial distance
        const angle = angles[i] + time * spinSpeed; // Update angle based on time
        const i3 = i * 3;
        array[i3] = Math.cos(angle) * radius; // x
        array[i3 + 2] = Math.sin(angle) * radius; // z
      }

      particlesRef.current.needsUpdate = true;
    }
  });

  return (
    <points>
      <bufferGeometry>
        <bufferAttribute
          ref={particlesRef}
          attach="attributes-position"
          array={particles.positions}
          itemSize={3}
          count={particles.positions.length / 3}
        />
        <bufferAttribute
          attach="attributes-color"
          array={particles.colors}
          itemSize={3}
          count={particles.colors.length / 3}
        />
      </bufferGeometry>
      <pointsMaterial
        size={size}
        vertexColors
        transparent
        blending={THREE.AdditiveBlending}
        depthWrite={false}
      />
    </points>
  );
};
