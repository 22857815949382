import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useMemo, useState } from "react";
import { ClassNames } from "./classes";
import { LazyVideo } from "./lazy-video";

export type IPanelSelectorProps = {
    panels: {
        label: string;
        description: string;
        url: string;
    }[];
}

export const PanelSelector: FC<IPanelSelectorProps> = ({ panels }) => {
    const [active, setActive] = useState<string>(panels[0].label);
    const activeFeature = useMemo(() => panels.find((panel) => panel.label === active), [active, panels]);

    return <div className="relative flex flex-col gap-8">
    <div className="flex gap-4 items-center max-md:flex-wrap">
        <AnimatePresence mode="wait">
            {panels.map((panel, index) => (
                <motion.div
                    key={panel.label}
                    onClick={() => setActive(panel.label)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                    <div className={classNames(
                            ClassNames.Text,
                            "px-6 py-3 rounded-full cursor-pointer relative max-md:text-xs",
                        )}>
                        {panel.label}
                        <AnimatePresence mode="wait">
                            {active === panel.label && (
                                <motion.div
                                    className={classNames(ClassNames.Text, "absolute inset-0 bg-white/5 rounded-full shadow-lg -z-[1]")}
                                    layoutId="pill"
                                    transition={{
                                        type: "spring",
                                        stiffness: 300,
                                        damping: 30,
                                    }}
                                />
                            )}
                        </AnimatePresence>
                    </div>
                </motion.div>
            ))}
        </AnimatePresence>
    </div>
    {
        activeFeature != null &&
        <motion.div
            key={active}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.4 }}
            className={classNames(ClassNames.Text, "text-lg h-[80vh] max-md:h-auto md:w-[80vw] max-w-[1200px] max-md:max-w-[calc(100vw-48px)] flex flex-col gap-8")}
        >
            <div className={classNames(ClassNames.GradientText, "text-3xl max-md:text-2xl")}>
                {activeFeature.description}
            </div>
            <LazyVideo src={activeFeature.url} />
        </motion.div>
    }
</div>
}