import { FC } from "react";
import { Header } from "../../components/header";
import { Page } from "../../components/page";
import { Tutorial } from "./tutorial";

export const MongoDBUseCase: FC = () => {
    return <Page>
        <Header />
        <Tutorial
            title="MongoDB"
            description="Set up a MongoDB instance for scalable NoSQL data storage"
            baseImage="/demos/use-cases/mongodb/base.webp"
            steps={[
                {
                    title: "Deploy MongoDB",
                    description: "Launch MongoDB for scalable NoSQL data storage.",
                    url: "/demos/use-cases/mongodb/mongodb.mp4",
                    extras: {
                        image: "mongo:latest",
                        environmentVariables: {
                            MONGO_INITDB_ROOT_USERNAME: "root",
                            MONGO_INITDB_ROOT_PASSWORD: "rootpassword",
                        },
                        ports: "27017",
                    },
                },
                {
                    title: "Deploy the Application",
                    description: "Launch the application that interacts with the MongoDB database. This service serves the frontend or API for user interaction.",
                    actionLabel: "Try Now",
                    extras: {},
                },
            ]}
        />
    </Page>
}
